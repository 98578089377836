import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = subcategories =>
{
  const data = subcategories.map(subcategory => ({
    id: subcategory.id,
    categoria: subcategory.category_name,
    nome: subcategory.name,
    descricao_seo: subcategory.seo_description,
    cor: subcategory.color_base,
    aparecer_menu: subcategory.show_in_menu === true ? 'Sim' : 'Não',
    conteudo_reduzido: subcategory.short_content === true ? 'Sim' : 'Não',
    criado_em: subcategory.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'categoria', 'nome', 'descricao_seo', 'cor', 'aparecer_menu',
             'conteudo_reduzido', 'criado_em']
  });
  downloadCSV(csv, 'subcategories');
}

export default exporter;
