import React from 'react';
import {
  Show,
  TextField,
  SimpleShowLayout
} from 'react-admin';
import SurveyOptionsField from '../../ui/survey-options-field';

const SurveyShow = () => (
  <Show
    title="Enquete">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="program.name" label="Programa"/>
      <TextField source="question" label="Questão"/>
      <TextField source="total_votes" label="Total de Votos"/>
      <TextField source="init_at_format" label="Início"/>
      <TextField source="end_at_format" label="Fim"/>
      <SurveyOptionsField label="Opções" sortable={false}/>
    </SimpleShowLayout>
  </Show>
);

export default SurveyShow;
