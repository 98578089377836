import React from 'react';
import {
  Create,
  SimpleForm,
  FileField,
  FileInput
} from 'react-admin';
import {
  file
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  file: file()
});

const CodeOfConductCreate = () =>
{
  return (
    <Create
      title="Novo Código de Conduta"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <FileInput source="file" label="Arquivo em PDF" accept="application/pdf" required>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export default CodeOfConductCreate;
