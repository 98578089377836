import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = participants =>
{
  const data = participants.map(participant => ({
    id: participant.user_id,
    enquete: participant.survey_name,
    name: participant.name,
    email: participant.email,
    phone: participant.phone,
    time: participant.team_name,
    criado_em: participant.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'enquete', 'name', 'email', 'phone', 'time', 'criado_em']
  });
  downloadCSV(csv, 'surveyParticipants');
}

export default exporter;
