import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  type_phone: hasValue(),
  phone: hasValue()
});

const PhoneCreate = () =>
{
  return (
    <Create
      title="Novo Telefone"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <ReferenceInput source="type_phone" reference="phone/types">
          <SelectInput label="Tipo de Telefone" optionText="name" optionValue="type" fullWidth required />
        </ReferenceInput>
        <TextInput source="phone" label="Telefone" fullWidth required />
      </SimpleForm>
    </Create>
  )
}

export default PhoneCreate;
