import React from 'react';
import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const styles =
{
  main:
  {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 }
};

const PresenterField = ({ source }) =>
{
  const record = useRecordContext();
  return (
    <span style={styles.main}>
    {
      record.presenters &&
        record.presenters.map(presenter => (
            <Chip
              key={presenter.id}
              style={styles.chip}
              label={presenter.name}
            />
        ))
    }
    </span>
  );
};

PresenterField.defaultProps =
{
  addLabel: true,
  source: 'Apresentadores'
};

export default PresenterField;
