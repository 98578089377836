import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = typesphone =>
{
  const data = typesphone.map(typephone => ({
    id: typephone.id,
    nome: typephone.name,
    criado_em: typephone.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'typesphone');
}

export default exporter;
