import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = presenters =>
{
  const data = presenters.map(presenter => ({
    id: presenter.id,
    nome: presenter.name,
    instagram: presenter.instagram,
    criado_em: presenter.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'instagram', 'criado_em']
  });
  downloadCSV(csv, 'presenters');
}

export default exporter;
