import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import ProgramExporter from '../../exporter/program-exporter';
import PresenterField from '../../ui/presenter-field';

const ProgramList = (props) => (
  <List
    title="Programas"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ ProgramExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <CustomImageField source="image_cover" label="Imagem" sortable={false}/>
      <TextField source="category.name" label="Categoria" sortable={false}/>
      <TextField source="dailymotion_playlist_id" label="Playlist Id"/>
      <TextField source="name" label="Nome" sortable={false}/>
      <TextField source="description" label="Descrição" sortable={false}/>
      <TextField source="days_group.name" label="Dia(s)" sortable={false}/>
      <TextField source="init_at_format" label="Início" sortable={false}/>
      <TextField source="end_at_format" label="Fim" sortable={false}/>
      <PresenterField label="Apresentadores" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ProgramList;
