import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterArticle = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Colunista" source="columnist_id" reference="columnist" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn >
    <SelectInput label="Colunista" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterArticle;
