import { stringify } from "query-string";
import jwt from "jwt-simple";
import axios from "axios";
import config from "../config/config";
import paramsToFormData from "../utils/params-to-form-data";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError
} from "react-admin";

const apiUrl = config.api.url;
const apiKey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageAuth = config.local_storage.auth;

const authToken = () =>
{
  return JSON.parse(jwt.decode(localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)), jwtKey)).auth_token;
};

const headers = () =>
{
  const token = authToken();
  return {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Authorization": "Bearer " + token
  };
};

const provider = async (type, resource, params) =>
{
  let url = "";

  if (type === CREATE)
  {
    if (resource === 'news')
      params.data.type_news = 0;
    else if (resource === 'article')
      params.data.type_news = 1;
  }

  const options =
  {
    headers: headers(),
    data: paramsToFormData(params.data)
  };

  switch (type)
  {
    case GET_LIST:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        apikey: apiKey
      };

      if (resource === 'user')
        url = `${apiUrl}/user/list?${stringify(query)}`;
      else if (resource === 'team')
        url = `${apiUrl}/team/list?${stringify(query)}`;
      else if (resource === 'columnist')
        url = `${apiUrl}/columnist/list?${stringify(query)}`;
      else if (resource === 'category')
        url = `${apiUrl}/category/list?${stringify(query)}`;
      else if (resource === 'subcategory')
        url = `${apiUrl}/subcategory/list?${stringify(query)}`;
      else if (resource === 'podcast')
        url = `${apiUrl}/podcast/list?${stringify(query)}`;
      else if (resource === 'program')
        url = `${apiUrl}/program/list?${stringify(query)}`;
      else if (resource === 'liveEvent')
        url = `${apiUrl}/liveevent/list?${stringify(query)}`;
      else if (resource === 'instareels')
        url = `${apiUrl}/instareels/list?${stringify(query)}`;
      else if (resource === 'phone')
        url = `${apiUrl}/phone/list?${stringify(query)}`;
      else if (resource === 'url')
        url = `${apiUrl}/url/list?${stringify(query)}`;
      else if (resource === 'news')
      {
        query.type_news = 0;
        url = `${apiUrl}/news/list?${stringify(query)}`;
      }
      else if (resource === 'article')
      {
        query.type_news = 1;
        url = `${apiUrl}/news/list?${stringify(query)}`;
      }
      else if (resource === 'gender')
        url = `${apiUrl}/gender/list?${stringify(query)}`;
      else if (resource === 'termsOfUse')
        url = `${apiUrl}/termsofuse/list?${stringify(query)}`;
      else if (resource === 'privacyPolicy')
        url = `${apiUrl}/privacypolicy/list?${stringify(query)}`;
      else if (resource === 'codeOfConduct')
        url = `${apiUrl}/codeofconduct/list?${stringify(query)}`;
      else if (resource === 'file')
        url = `${apiUrl}/file/list?${stringify(query)}`;
      else if (resource === 'chatDenounce')
        url = `${apiUrl}/chat/denounces?${stringify(query)}`;
      else if (resource === 'chatBan')
        url = `${apiUrl}/chat/banned?${stringify(query)}`;
      else if (resource === 'survey')
        url = `${apiUrl}/survey/list?${stringify(query)}`;
      else if (resource === 'surveyParticipants')
        url = `${apiUrl}/survey/${params.filter.survey_id ? params.filter.survey_id : 0}/participants?${stringify(query)}`;
      else if (resource === 'promotionSubscribers')
        url = `${apiUrl}/promotion/${params.filter.promotion_id ? params.filter.promotion_id : 0}/subscribers?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_ONE:
    {
      const query = {
        apikey: apiKey
      };

      if (resource === 'news')
        url = `${apiUrl}/news/byId/${params.id}?${stringify(query)}`;
      else if (resource === 'article')
        url = `${apiUrl}/news/byId/${params.id}?${stringify(query)}`;
      else if (resource === 'podcast')
        url = `${apiUrl}/podcast/byId/${params.id}?${stringify(query)}`;
      else if (resource === 'columnist')
        url = `${apiUrl}/columnist/byId/${params.id}?${stringify(query)}`;
      else if (resource === 'program')
        url = `${apiUrl}/program/byId/${params.id}?${stringify(query)}`;
      else if (resource === 'liveEvent')
        url = `${apiUrl}/liveevent/byId/${params.id}?${stringify(query)}`;
        else if (resource === 'instareels')
          url = `${apiUrl}/instareels/byId/${params.id}?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_MANY:
    {
      const query = {
        apikey: apiKey
      };

      if (resource === 'gender')
        url = `${apiUrl}/gender/list?${stringify(query)}`;
      else if (resource === 'columnist')
        url = `${apiUrl}/columnist/list?${stringify(query)}`;
      else if (resource === 'subcategory')
        url = `${apiUrl}/subcategory/list?${stringify(query)}`;
      else if (resource === 'program')
        url = `${apiUrl}/program/list?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}/?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_MANY_REFERENCE:
    {
      const query = {
        apikey: apiKey
      };

      if (resource === 'survey/participants')
        url = `${apiUrl}/survey/${params.id}/participants?${stringify(query)}`;
      else if (resource === 'promotion/subscribers')
        url = `${apiUrl}/promotion/${params.id}/subscribers?${stringify(query)}`;
      else if (resource === 'promotionquiz/ranking')
        url = `${apiUrl}/promotionquiz/${params.id}/ranking?${stringify(query)}`;
      else if (resource === 'chat/denounce/status')
        url = `${apiUrl}/chat/denounce/status?${stringify(query)}`;
      else
      {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query =
        {
          sort: JSON.stringify([field, order]),
          apikey: apiKey,
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(
          {
            ...params.filter,
            [params.target]: params.id,
          })
        };

        url = `${apiUrl}/${resource}?${stringify(query)}`;
      }

      options.url = url;

      break;
    }
    case UPDATE:
    {
      const query = {
        apikey: apiKey
      };

      if (resource === 'chatDenounce')
        url = `${apiUrl}/chat/denounce/${params.id}?${stringify(query)}`;
      else if (resource === 'chatBan')
        url = `${apiUrl}/chat/ban/${params.id}?${stringify(query)}`;
      else if (resource === 'article')
        url = `${apiUrl}/news/${params.id}?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;

      options.method = "PUT";
      options.url = url;

      break;
    }
    case CREATE:
    {
      const query = {
        apikey: apiKey
      };

      if (resource === 'article')
        url = `${apiUrl}/news?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.method = "POST";
      options.url = url;

      break;
    }
    case DELETE:
    {
      url = `${apiUrl}/${resource}/${params.id}`;

      options.method = "DELETE";
      options.url = url;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios(options)
  .then((res) =>
  {
    return res.data;
  })
  .then((response) =>
  {
    switch (type)
    {
      case GET_LIST:
      case GET_MANY:
        const data = response.data;
        const meta = response.meta;
        return {
          data: data,
          total: meta.total,
          pagination:
          {
            page: meta.page,
            perPage: meta.perpage
          }
        }
      case GET_MANY_REFERENCE:
        return {
          data: response.result,
          total: response.result.lenght,
        };
      case GET_ONE:
        return {
          data: response.result,
        };
      case UPDATE:
      case CREATE:
        return {
          data: response.result,
        };
      default:
        return {
          data: []
        };
    }
  })
  .catch((error) =>
  {
    if (error.response.status < 200 || error.response.status >= 300)
    {
      if (error.response.data && error.response.data.error)
        return Promise.reject(new HttpError(error.response.data.error, error.response.status));
      else
        return Promise.reject(new HttpError(error.response.statusText, error.response.status));
    }
  });
};

export default provider;
