import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import CustomFilterPromotionSubscribers from '../../filter/custom-filter-promotion-subscribers';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import PromotionSubscribersExporter from '../../exporter/promotion-subscribers-exporter';

const PromotionSubscribersList = (props) => (
  <List
    title="Inscritos"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterPromotionSubscribers }
    exporter={ PromotionSubscribersExporter }
    actions={ <CustomListDefaultToolbar hasFilter={false} hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="promotion_name" label="Promoção" sortable={false}/>
      <TextField source="profile.name" label="Nome" sortable={false}/>
      <TextField source="email" label="E-mail" sortable={false}/>
      <TextField source="username" label="Username" sortable={false}/>
      <TextField source="profile.phone" label="Telefone" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
    </Datagrid>
  </List>
);

export default PromotionSubscribersList;
