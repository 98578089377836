import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = urls =>
{
  const data = urls.map(url => ({
    id: url.id,
    tipo: url.type_url_name,
    url: url.url,
    criado_em: url.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'tipo', 'url', 'criado_em']
  });
  downloadCSV(csv, 'urls');
}

export default exporter;
