import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = privacypolicies =>
{
  const data = privacypolicies.map(privacypolicy => ({
    id: privacypolicy.id,
    url: privacypolicy.secure_url,
    criado_em: privacypolicy.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'url', 'criado_em']
  });
  downloadCSV(csv, 'privacypolicy');
}

export default exporter;
