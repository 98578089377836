import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField
} from 'react-admin';
import CustomFilterContact from '../../filter/custom-filter-contact';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ContactExporter from '../../exporter/contact-exporter';

const ContactList = (props) => (
  <List
    title="Contatos"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterContact }
    exporter={ ContactExporter }
    actions={ <CustomListDefaultToolbar hasFilter={false} hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <ChipField source="type_contact_name" label="Tipo de Contato" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="phone" label="Telefone" sortable={false} />
      <TextField source="subject" label="Assunto" sortable={false} />
      <TextField source="message" label="Mensagem" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
    </Datagrid>
  </List>
);

export default ContactList;
