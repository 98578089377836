import React from 'react';
import {
  Show,
  TextField,
  RichTextField,
  DateField,
  TabbedShowLayout,
  BooleanField
} from 'react-admin';
import CustomImageField from '../../ui/custom-image-field';

const ArticleShow = () => (
  <Show
    title="Artigo">
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Dados">
        <TextField source="id" />
        <TextField source="subcategory.category.name" label="Categoria"/>
        <TextField source="subcategory.name" label="Subcategoria"/>
        <TextField source="columnist.name" label="Colunista" sortable={false}/>
        <TextField source="title" label="Título"/>
        <TextField source="description" label="Descrição"/>
        <TextField source="news_date_format" label="Data"/>
        <TextField source="publication_date_format" label="Data de Publicação"/>
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime/>
        <DateField source="updated_at" locales="pt-BR" label="Atualizado em" showTime/>
        <BooleanField source="active"/>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Imagens">
        <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
        <CustomImageField source="image_cover" label="Imagem Cover" sortable={false}/>
        <TextField source="image_cover_credits" label="Créditos Imagem Cover" sortable={false}/>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Texto">
        <RichTextField source="text" label="Texto"/>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default ArticleShow;
