import { SearchInput, ReferenceInput, SelectInput } from "react-admin";

const CustomFilterPromotionSubscribers = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Promoção" source="promotion_id" reference="promotion" alwaysOn >
    <SelectInput label="Promoção" optionText="title" optionValue="id" />
  </ReferenceInput>
];

export default CustomFilterPromotionSubscribers;
