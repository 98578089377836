import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import CustomFilterSurveyParticipants from '../../filter/custom-filter-survey-participants';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import SurveyParticipantsExporter from '../../exporter/survey-participants-exporter';

const SurveyParticipantsList = (props) => (
  <List
    title="Participantes da Enquete"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterSurveyParticipants }
    exporter={ SurveyParticipantsExporter }
    actions={ <CustomListDefaultToolbar hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="survey_name" label="Enquete" sortable={false}/>
      <TextField source="name" label="Nome" sortable={false}/>
      <TextField source="email" label="E-mail" sortable={false}/>
      <TextField source="phone" label="Telefone" sortable={false}/>
      <TextField source="team_name" label="Time" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
    </Datagrid>
    </List>
);

export default SurveyParticipantsList;
