import { SearchInput, SelectInput } from "react-admin";

const CustomFilterChatDenounce = [
  <SearchInput source="q" alwaysOn />,
  <SelectInput label="Status" source="status_chat_denounce" alwaysOn choices={[
      { id: 0, name: 'DENOUNCED' },
      { id: 1, name: 'APPROVED' },
      { id: 2, name: 'BANED' }
  ]} />
];

export default CustomFilterChatDenounce;
