import React from 'react';
import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const styles =
{
  main:
  {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 }
};

const DenounciatorField = ({ source }) =>
{
  const record = useRecordContext();
  return (
    <span style={styles.main}>
    {
      record.denunciator &&
      <Chip
        key={record.id}
        style={styles.chip}
        label={record.denunciator.profile.name + ' - ' + record.denunciator.email}
      />
    }
    </span>
  );
};

DenounciatorField.defaultProps =
{
  addLabel: true,
  source: 'Denunciado por'
};

export default DenounciatorField;
