import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterUrl from '../../filter/custom-filter-url';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonEdit from '../../ui/button-edit';
import UrlExporter from '../../exporter/url-exporter';

const UrlList = (props) => (
  <List
    title="URLS"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterUrl }
    exporter={ UrlExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" label="Id" sortable={false} />
      <ChipField source="type_url_name" label="Tipo da URL" sortable={false} />
      <TextField source="url" label="URL" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default UrlList;
