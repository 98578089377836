import React from 'react';
import {
  Show,
  TextField,
  SimpleShowLayout
} from 'react-admin';
import QuizQuestionField from '../../ui/quiz-question-field';

const QuizShow = () => (
  <Show
    title="Quiz">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="promotion_quiz_title" label="Promoção"/>
      <TextField source="title" label="Título"/>
      <TextField source="init_at_format" label="Início"/>
      <TextField source="end_at_format" label="Fim"/>
      <QuizQuestionField label="Questões" sortable={false}/>
    </SimpleShowLayout>
  </Show>
);

export default QuizShow;
