import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ImageField,
  ImageInput,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  name: hasValue(),
  position: hasValue(),
  image: file(),
});

const BrandCreate = () => {
  return (
    <Create title="Novo Marca" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="name" label="Nome" fullWidth required />
        <NumberInput source="position" label="Posição" fullWidth required />
        <ImageInput
          source="image"
          label="Imagem (300x300) Max: 2MB"
          maxSize={2000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
          required
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default BrandCreate;
