import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  name: hasValue(),
  instagram: hasValue(),
  image: file(),
});

const PresenterCreate = () => {
  return (
    <Create title="Novo Apresentador" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput source="instagram" label="Instagram" fullWidth required />
        <ImageInput
          source="image"
          label="Thumb (100x100) Max: 1MB"
          maxSize={1000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
          required
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default PresenterCreate;
