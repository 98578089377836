import React from 'react';
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout
} from 'react-admin';

const UserShow = () => (
  <Show
    title="Usuário Ouvinte">
    <TabbedShowLayout>
      <Tab label="Dados do Usuário">
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="email" label="E-mail" />
          <TextField source="username" label="Username"/>
        </SimpleShowLayout>
      </Tab>
      <Tab label="Perfil" path="profile" >
        <SimpleShowLayout>
          <TextField source="profile.name" label="Nome"/>
          <TextField source="profile.birthdate" label="Data de Nascimento" />
          <TextField source="profile.phone" label="Telefone"/>
        </SimpleShowLayout>
      </Tab>
      <Tab label="Endereço" path="address" >
        <SimpleShowLayout>
          <TextField source="address.address" label="Endereço"/>
          <TextField source="address.zip_code" label="Cep" />
          <TextField source="address.neighborhood" label="Bairro"/>
          <TextField source="address.city" label="Cidade"/>
          <TextField source="address.state" label="Estado" />
        </SimpleShowLayout>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserShow;
