import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  title: hasValue(),
  init_at: hasValue(),
  end_at: hasValue()
});

const QuizCreate = () =>
{
  return (
    <Create
      title="Novo Quiz"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="title" label="Título" fullWidth required />
        <DateTimeInput source="init_at" label="Início" required />
        <DateTimeInput source="end_at" label="Término" required />
      </SimpleForm>
    </Create>
  )
}

export default QuizCreate;
