import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomRichTextInput from "../../ui/custom-rich-text-input";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  title: hasValue(),
  description: hasValue(),
  number_winners: hasValue(),
  init_at: hasValue(),
  end_at: hasValue(),
  image: file(),
});

const PromotionCreate = () => {
  return (
    <Create title="Nova Promoção" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="title" label="Título" fullWidth required />
        <TextInput
          multiline
          source="description"
          label="Descrição"
          fullWidth
          required
        />
        <NumberInput
          inputProps={{ min: 1 }}
          source="number_winners"
          fullWidth
          required
          label="Número de Ganhadores"
        />
        <TextInput
          source="external_url"
          label="Url Externa"
          type="url"
          fullWidth
        />
        <DateTimeInput source="init_at" label="Início" required />
        <DateTimeInput source="end_at" label="Término" required />
        <CustomRichTextInput
          source="regulation"
          label="Regulamento"
          fullWidth
        />
        <ImageInput
          source="image"
          label="Imagem (260x120) Max: 2MB"
          maxSize={2000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
          required
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default PromotionCreate;
