import React from 'react';
import placeholder from '../assets/placeholder.png';
import { useRecordContext } from "react-admin";

const CustomImageField = ({ source, label, fileField }) =>
{
  const record = useRecordContext();
  if (record && record[source])
  {
      if (Array.isArray(record[source]))
      {
        if (record[source].length > 0)
          return (
            <img src={record[source][0].secure_url_image} alt="Thumb" width="100" />
          );
      }
      else if (fileField != null && record[source][fileField] != null)
        return (
          <img src={record[source][fileField].secure_url_image} alt="Thumb" width="100" />
        );
      else if (record[source] != null)
        return (
          <img src={record[source].secure_url_image} alt="Thumb" width="100" />
        );
  }

  return (
    <img src={placeholder} alt="Placeholder" width="55" />
  );
};

export default CustomImageField;
