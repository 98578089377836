import React from "react";
import { Admin, Resource, Layout } from "react-admin";
import Login from "./view/login";
import Dashboard from "./view/dashboard";
import polyglotI18nProvider from "ra-i18n-polyglot";
import ptBrMessages from 'ra-language-pt-br';
import dataProvider from "./provider/data-provider";
import authProvider from "./provider/auth-provider";
import config from "./config/config";

// ADMIN
import AdminIcon from "@mui/icons-material/People";
import AdminList from "./container/admin/list";
import AdminCreate from "./container/admin/create";
import AdminEdit from "./container/admin/edit";
import AdminShow from "./container/admin/show";

// ARTICLE
import ArticleIcon from "@mui/icons-material/Article";
import ArticleList from "./container/article/list";
import ArticleCreate from "./container/article/create";
import ArticleEdit from "./container/article/edit";
import ArticleShow from "./container/article/show";

// BRAND
import BrandIcon from "@mui/icons-material/BrandingWatermark";
import BrandList from "./container/brand/list";
import BrandCreate from "./container/brand/create";
import BrandEdit from "./container/brand/edit";

// BUTTON MENU
import ButtonMenuIcon from "@mui/icons-material/EditAttributes";
import ButtonMenuList from "./container/buttonmenu/list";
import ButtonMenuCreate from "./container/buttonmenu/create";
import ButtonMenuEdit from "./container/buttonmenu/edit";

// CATEGORY
import CategoryIcon from "@mui/icons-material/Category";
import CategoryList from "./container/category/list";
import CategoryCreate from "./container/category/create";
import CategoryEdit from "./container/category/edit";

// CHAT BAN
import ChatBanIcon from "@mui/icons-material/HighlightOff";
import ChatBanList from "./container/chatban/list";

// CHAT DENOUNCE
import ChatDenounceIcon from "@mui/icons-material/Gavel";
import ChatDenounceList from "./container/chatdenounce/list";

// CODE OF CONDUCT
import CodeOfConductIcon from "@mui/icons-material/Code";
import CodeOfConductList from "./container/codeofconduct/list";
import CodeOfConductCreate from "./container/codeofconduct/create";

// COLUMNIST
import ColumnistIcon from "@mui/icons-material/Person";
import ColumnistList from "./container/columnist/list";
import ColumnistCreate from "./container/columnist/create";
import ColumnistEdit from "./container/columnist/edit";

// CONTACT
import ContactIcon from "@mui/icons-material/ContactMail";
import ContactList from "./container/contact/list";

// GENDER
import GenderIcon from "@mui/icons-material/Wc";
import GenderList from "./container/gender/list";

// FILE
import FileIcon from "@mui/icons-material/FileCopy";
import FileList from "./container/file/list";
import FileCreate from "./container/file/create";
import FileEdit from "./container/file/edit";

// INSTA REELS
import InstaReelsIcon from "@mui/icons-material/Instagram";
import InstaReelsList from "./container/instareels/list";
import InstaReelsCreate from "./container/instareels/create";
import InstaReelsEdit from "./container/instareels/edit";
import InstaReelsShow from "./container/instareels/show";

// LIVE EVENT
import LiveEventIcon from "@mui/icons-material/EventAvailable";
import LiveEventList from "./container/liveevent/list";
import LiveEventCreate from "./container/liveevent/create";
import LiveEventEdit from "./container/liveevent/edit";

// NEWS
import NewsIcon from "@mui/icons-material/Newspaper";
import NewsList from "./container/news/list";
import NewsCreate from "./container/news/create";
import NewsEdit from "./container/news/edit";
import NewsShow from "./container/news/show";

// PHONE
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneList from "./container/phone/list";
import PhoneCreate from "./container/phone/create";
import PhoneEdit from "./container/phone/edit";

// PRESENTER
import PresenterIcon from "@mui/icons-material/Person3";
import PresenterList from "./container/presenter/list";
import PresenterCreate from "./container/presenter/create";
import PresenterEdit from "./container/presenter/edit";

// PRIVACY POLICY
import PrivacyPolicyIcon from "@mui/icons-material/VpnKey";
import PrivacyPolicyList from "./container/privacypolicy/list";
import PrivacyPolicyCreate from "./container/privacypolicy/create";

// PROGRAM
import ProgramIcon from "@mui/icons-material/Tv";
import ProgramList from "./container/program/list";
import ProgramCreate from "./container/program/create";
import ProgramEdit from "./container/program/edit";
import ProgramShow from "./container/program/show";

// PROMOTION
import PromotionIcon from "@mui/icons-material/Redeem";
import PromotionList from "./container/promotion/list";
import PromotionCreate from "./container/promotion/create";
import PromotionEdit from "./container/promotion/edit";
import PromotionShow from "./container/promotion/show";

// PROMOTION QUIZ
import PromotionQuizIcon from "@mui/icons-material/CastForEducation";
import PromotionQuizList from "./container/promotionquiz/list";

// PROMOTION QUIZ Ranking
import PromotionQuizRankingIcon from "@mui/icons-material/Dvr";
import PromotionQuizRankingList from "./container/promotionquizranking/list";

// PROMOTION SUBSCRIBERS
import PromotionSubscribersIcon from "@mui/icons-material/AssignmentInd";
import PromotionSubscribersList from "./container/promotionsubscribers/list";

// QUESTION
import QuestionIcon from "@mui/icons-material/Create";
import QuestionList from "./container/question/list";
import QuestionCreate from "./container/question/create";
import QuestionEdit from "./container/question/edit";
import QuestionShow from "./container/question/show";

// QUIZ
import QuizIcon from "@mui/icons-material/QuestionAnswer";
import QuizList from "./container/quiz/list";
import QuizCreate from "./container/quiz/create";
import QuizEdit from "./container/quiz/edit";
import QuizShow from "./container/quiz/show";

// SUBCATEGORY
import SubcategoryIcon from "@mui/icons-material/CategoryOutlined";
import SubcategoryList from "./container/subcategory/list";
import SubcategoryCreate from "./container/subcategory/create";
import SubcategoryEdit from "./container/subcategory/edit";

// SURVEY
import SurveyIcon from "@mui/icons-material/HelpOutline";
import SurveyList from "./container/survey/list";
import SurveyCreate from "./container/survey/create";
import SurveyEdit from "./container/survey/edit";
import SurveyShow from "./container/survey/show";

// SURVEY PARTICIPANTS
import SurveyParticipantsIcon from "@mui/icons-material/AssignmentInd";
import SurveyParticipantsList from "./container/surveyparticipants/list";

// TEAM
import TeamIcon from "@mui/icons-material/Security";
import TeamList from "./container/team/list";
import TeamCreate from "./container/team/create";
import TeamEdit from "./container/team/edit";

// TERMS OF USE
import TermsOfUseIcon from "@mui/icons-material/Description";
import TermsOfUseList from "./container/termsofuse/list";
import TermsOfUseCreate from "./container/termsofuse/create";

// TYPE PHONE
import TypePhoneIcon from "@mui/icons-material/PermDeviceInformation";
import TypePhoneList from "./container/typephone/list";
import TypePhoneCreate from "./container/typephone/create";
import TypePhoneEdit from "./container/typephone/edit";

// URL
import UrlIcon from "@mui/icons-material/Http";
import UrlList from "./container/url/list";
import UrlCreate from "./container/url/create";
import UrlEdit from "./container/url/edit";

// USER
import UserIcon from "@mui/icons-material/People";
import UserList from "./container/user/list";
import UserEdit from "./container/user/edit";
import UserShow from "./container/user/show";

import theme from "./theme";
import Sidebar from "./components/sidebar";

const i18nProvider = polyglotI18nProvider(() => ptBrMessages);

const App = () => (
  <Admin
    theme={theme}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={Login}
    dashboard={Dashboard}
    layout={(props) => <Layout {...props} sidebar={Sidebar} />}
    title="98 Live - Admin">
    {(permissions) =>
    [
      // NEWS
      <Resource
        name =  "news"
        options = {{ label: "Notícias" }}
        icon = { NewsIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_news)) ? NewsList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_news)) ? NewsCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_news)) ? NewsEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_news)) ? NewsShow : null }
      />,

      // ARTICLE
      <Resource
        name =  "article"
        options = {{ label: "Artigos" }}
        icon = { ArticleIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_articles)) ? ArticleList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_articles)) ? ArticleCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_articles)) ? ArticleEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_articles)) ? ArticleShow : null }
      />,

      // CATEGORY
      <Resource
        name =  "category"
        options = {{ label: "Categorias" }}
        icon = { CategoryIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_categories)) ? CategoryList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_categories)) ? CategoryCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_categories)) ? CategoryEdit : null }
      />,

      // SUBCATEGORY
      <Resource
        name =  "subcategory"
        options = {{ label: "Subcategorias" }}
        icon = { SubcategoryIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_categories)) ? SubcategoryList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_categories)) ? SubcategoryCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_categories)) ? SubcategoryEdit : null }
      />,

      // COLUMNIST
      <Resource
        name =  "columnist"
        options = {{ label: "Colunistas" }}
        icon = { ColumnistIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_columnist)) ? ColumnistList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_columnist)) ? ColumnistCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_columnist)) ? ColumnistEdit : null }
      />,

      // PRESENTER
      <Resource
        name =  "presenter"
        options = {{ label: "Apresentadores" }}
        icon = { PresenterIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? PresenterList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? PresenterCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? PresenterEdit : null }
      />,

      // PROGRAM
      <Resource
        name =  "program"
        options = {{ label: "Programas" }}
        icon = { ProgramIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? ProgramList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? ProgramCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? ProgramEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_programs)) ? ProgramShow : null }
      />,

      // FILE
      <Resource
        name =  "file"
        options = {{ label: "Arquivos para Download" }}
        icon = { FileIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_file_download)) ? FileList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_file_download)) ? FileCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_file_download)) ? FileEdit : null }
      />,

      // CHAT BAN
      <Resource
        name =  "chatBan"
        options = {{ label: "Banidos do Chat" }}
        icon = { ChatBanIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_chat)) ? ChatBanList : null }
      />,

      // BUTTON MENU
      <Resource
        name =  "buttonMenu"
        options = {{ label: "Botões do Menu" }}
        icon = { ButtonMenuIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_button_menu)) ? ButtonMenuList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_button_menu)) ? ButtonMenuCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_button_menu)) ? ButtonMenuEdit : null }
      />,

      // CODE OF CONDUCT
      <Resource
        name =  "codeOfConduct"
        options = {{ label: "Códigos de Conduta" }}
        icon = { CodeOfConductIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_archives)) ? CodeOfConductList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_archives)) ? CodeOfConductCreate : null }
      />,

      // CONTACT
      <Resource
        name =  "contact"
        options = {{ label: "Contatos" }}
        icon = { ContactIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_customers)) ? ContactList : null }
      />,

      // CHAT DENOUNCE
      <Resource
        name =  "chatDenounce"
        options = {{ label: "Denuncias do Chat" }}
        icon = { ChatDenounceIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_chat)) ? ChatDenounceList : null }
      />,

      // SURVEY
      <Resource
        name =  "survey"
        options = {{ label: "Enquetes" }}
        icon = { SurveyIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_surveys)) ? SurveyList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_surveys)) ? SurveyCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_surveys)) ? SurveyEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_surveys)) ? SurveyShow : null }
      />,

      // SURVEY PARTICIPANTS
      <Resource
        name =  "surveyParticipants"
        options = {{ label: "Enquete - Participantes" }}
        icon = { SurveyParticipantsIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_surveys)) ? SurveyParticipantsList : null }
      />,

      // LIVE EVENT
      <Resource
        name =  "liveEvent"
        options = {{ label: "Eventos na Live" }}
        icon = { LiveEventIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_live_events)) ? LiveEventList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_live_events)) ? LiveEventCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_live_events)) ? LiveEventEdit : null }
      />,

      // GENDER
      <Resource
        name =  "gender"
        options = {{ label: "Gêneros" }}
        icon = { GenderIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_genders)) ? GenderList : null }
      />,

      // BRAND
      <Resource
        name =  "brand"
        options = {{ label: "Marcas Splash" }}
        icon = { BrandIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_brands)) ? BrandList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_brands)) ? BrandCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_brands)) ? BrandEdit : null }
      />,

      // PRIVACY POLICY
      <Resource
        name =  "privacyPolicy"
        options = {{ label: "Política de Privacidade" }}
        icon = { PrivacyPolicyIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_archives)) ? PrivacyPolicyList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_archives)) ? PrivacyPolicyCreate : null }
      />,

      // PROMOTION QUIZ
      <Resource
        name =  "promotionQuiz"
        options = {{ label: "Promoção Quiz" }}
        icon = { PromotionQuizIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? PromotionQuizList : null }
      />,

      // PROMOTION SUBSCRIBERS
      <Resource
        name =  "promotionSubscribers"
        options = {{ label: "Promoção - Inscritos" }}
        icon = { PromotionSubscribersIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotions)) ? PromotionSubscribersList : null }
      />,

      // PROMOTION
      <Resource
        name =  "promotion"
        options = {{ label: "Promoções" }}
        icon = { PromotionIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotions)) ? PromotionList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotions)) ? PromotionCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotions)) ? PromotionEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotions)) ? PromotionShow : null }
      />,

      // QUESTION
      <Resource
        name =  "question"
        options = {{ label: "Questões" }}
        icon = { QuestionIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuestionList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuestionCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuestionEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuestionShow : null }
      />,

      // QUIZ
      <Resource
        name =  "quiz"
        options = {{ label: "Quizzes" }}
        icon = { QuizIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuizList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuizCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuizEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? QuizShow : null }
      />,

      // PROMOTION QUIZ RANKING
      <Resource
        name =  "PromotionQuizRanking"
        options = {{ label: "Ranking" }}
        icon = { PromotionQuizRankingIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_promotion_quiz)) ? PromotionQuizRankingList : null }
      />,

      // INSTA REELS
      <Resource
        name =  "instareels"
        options = {{ label: "Reels Instagram" }}
        icon = { InstaReelsIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_insta_reels)) ? InstaReelsList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_insta_reels)) ? InstaReelsCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_insta_reels)) ? InstaReelsEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_insta_reels)) ? InstaReelsShow : null }
      />,

      // PHONE
      <Resource
        name =  "phone"
        options = {{ label: "Telefones" }}
        icon = { PhoneIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_phones)) ? PhoneList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_phones)) ? PhoneCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_phones)) ? PhoneEdit : null }
      />,

      // TERMS OF USE
      <Resource
        name =  "termsOfUse"
        options = {{ label: "Termos de Uso" }}
        icon = { TermsOfUseIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_archives)) ? TermsOfUseList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_archives)) ? TermsOfUseCreate : null }
      />,

      // TEAM
      <Resource
        name =  "team"
        options = {{ label: "Times" }}
        icon = { TeamIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_teams)) ? TeamList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_teams)) ? TeamCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_teams)) ? TeamEdit : null }
      />,

      // TYPE PHONE
      <Resource
        name =  "typePhone"
        options = {{ label: "Tipos de Telefone" }}
        icon = { TypePhoneIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_phones)) ? TypePhoneList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_phones)) ? TypePhoneCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_phones)) ? TypePhoneEdit : null }
      />,

      // URLS
      <Resource
        name =  "url"
        options = {{ label: "URLS" }}
        icon = { UrlIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_urls)) ? UrlList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_urls)) ? UrlCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_urls)) ? UrlEdit : null }
      />,

      // USER ADMIN
      <Resource
        name =  "admin"
        options = {{ label: "Usuários Admin" }}
        icon = { AdminIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_users_admin)) ? AdminList : null }
        create = { (permissions && permissions.some(e => e.key === config.permissions.admin_users_admin)) ? AdminCreate : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_users_admin)) ? AdminEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_users_admin)) ? AdminShow : null }

      />,

      // USER
      <Resource
        name =  "user"
        options = {{ label: "Usuários Ouvinte" }}
        icon = { UserIcon }
        list = { (permissions && permissions.some(e => e.key === config.permissions.admin_customers)) ? UserList : null }
        edit = { (permissions && permissions.some(e => e.key === config.permissions.admin_customers)) ? UserEdit : null }
        show = { (permissions && permissions.some(e => e.key === config.permissions.admin_customers)) ? UserShow : null }
      />
    ]}
  </Admin>
);

export default App;
