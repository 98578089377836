import React from 'react';
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterChatDenounce from '../../filter/custom-filter-chat-denounce';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonApproveDenounce from '../../ui/button-approve-denounce';
import ButtonBan from '../../ui/button-ban';
import DenounciatorField from '../../ui/denounciator-field';
import DenouncedField from '../../ui/denounced-field';
import ChatDenounceExporter from '../../exporter/chat-denounce-exporter';
import rowChatDenounceStyle from '../../ui/row-chat-denounce-style';

const ChatDenounceList = (props) => (
  <List
    title="Denuncias do Chat"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterChatDenounce }
    exporter={ ChatDenounceExporter }
    actions={ <CustomListDefaultToolbar hasFilter={false} hasCreate={false} /> }>
    <Datagrid
      rowStyle={rowChatDenounceStyle()}
      bulkActionButtons={false}>
      <TextField source="status_chat_denounce_name" label="Status" sortable={false} />
      <TextField source="message" label="Mensagem" sortable={false} />
      <DenouncedField />
      <DenounciatorField />
      <TextField source="created_at_format" label="Enviada em" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonApproveDenounce />
        <ButtonBan />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ChatDenounceList;
