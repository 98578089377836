import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = surveys =>
{
  const data = surveys.map(survey => ({
    id: survey.id,
    programa: survey.program.name,
    questao: survey.question,
    numero_votos: survey.total_votes,
    inicio: survey.init_at_format,
    fim: survey.end_at_format,
    opcoes: survey.options_name,
    criado_em: survey.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'programa', 'questao', 'numero_votos', 'inicio', 'fim',
             'opcoes', 'criado_em']
  });
  downloadCSV(csv, 'surveys');
}

export default exporter;
