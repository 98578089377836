import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import LinkField from '../../ui/link-field';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import InstaReelsExporter from '../../exporter/insta-reels-exporter';

const InstaReelsList = (props) => (
  <List
    title="Reels do Instagram"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ InstaReelsExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="subcategory.name" label="Subcategoria" sortable={false}/>
      <LinkField source="url" label="Url" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default InstaReelsList;
