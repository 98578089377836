import React from "react";
import {
  Button,
  useRecordContext
} from "react-admin";
import IconLaunch from "@mui/icons-material/Launch";

const ButtonOpenLink = ({ source, ...props }) =>
{
  const record = useRecordContext();
  const onRequest = async (e) => { e.stopPropagation(); window.open(record[source], "_blank"); };

  return (
    <Button label={props.text} onClick={onRequest}>
      <IconLaunch />
    </Button>
  );
};

export default ButtonOpenLink;
