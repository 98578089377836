import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonDetail from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import UserExporter from '../../exporter/user-exporter';

const UserList = (props) => (
  <List
    title="Usuários Ouvinte"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ UserExporter }
    actions={ <CustomListDefaultToolbar hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="profile.name" label="Nome" sortable={false}/>
      <TextField source="email" label="E-mail" sortable={false}/>
      <TextField source="username" label="Username" sortable={false}/>
      <BooleanField source="active" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false}/>
      <GroupButtons label="Ações">
        <ButtonDetail />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default UserList;
