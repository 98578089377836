import React from 'react';
import {
  Show,
  TextField,
  RichTextField,
  DateField,
  TabbedShowLayout,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Datagrid
} from 'react-admin';
import CustomImageField from '../../ui/custom-image-field';

const NewsShow = () => (
  <Show
    title="Notícia">
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Dados">
        <TextField source="id" />
        <TextField source="subcategory.category.name" label="Categoria"/>
        <TextField source="subcategory.name" label="Subcategoria"/>
        <TextField source="title" label="Título"/>
        <TextField source="description" label="Descrição"/>
        <TextField source="signed_by" label="Assinada por" />
        <TextField source="news_date_format" label="Data"/>
        <TextField source="publication_date_format" label="Data de Publicação"/>
        <TextField source="featured_limit_date_format" label="Em Destaque Até"/>
        <TextField source="created_by.profile.name" label="Criada Por" sortable={false}/>
        <BooleanField source="featured" label="Destaque?"/>
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
        <DateField source="updated_at" locales="pt-BR" label="Atualizado em" showTime />
        <BooleanField source="active"/>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Imagens">
        <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
        <CustomImageField source="image_cover" label="Imagem Cover" sortable={false}/>
        <TextField source="image_cover_credits" label="Créditos Imagem Cover" sortable={false}/>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Texto">
        <RichTextField source="text" label="Texto"/>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Post Type(s)">
        <ArrayField source="post_types" label="">
          <Datagrid bulkActionButtons={false}>
            <TextField source="type_news_post_type_name" label="Tipo"/>
            <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
            <TextField source="paragraph" label="Parágrafo"/>
            <TextField source="title" label="Título"/>
            <TextField source="text_button" label="Texto do Botão"/>
            <TextField source="link" label="Link"/>
            <ArrayField source="informations" label="Informações">
              <SingleFieldList linkType={false}>
                <ChipField source="text" />
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default NewsShow;
