import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  gender_type: hasValue(),
  name: hasValue(),
  description: hasValue(),
  categories_id: hasValue(),
});

const ColumnistEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Colunista: " source="name" />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput
          source="gender_type"
          sort={{ field: "type", order: "ASC" }}
          filter={{ active: true }}
          reference="gender"
        >
          <SelectInput
            label="Gênero"
            optionText="name"
            optionValue="type"
            fullWidth
            required
          />
        </ReferenceInput>
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput
          multiline
          source="description"
          label="Descrição"
          fullWidth
          required
        />
        <ReferenceArrayInput
          source="categories_id"
          filter={{ active: true }}
          reference="category"
        >
          <SelectArrayInput
            label="Categoria(s)"
            optionText="name"
            optionValue="id"
            fullWidth
            required
          />
        </ReferenceArrayInput>
        <ImageInput
          source="image"
          label="Thumb (300x300) Max: 2MB"
          maxSize={2000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default ColumnistEdit;
