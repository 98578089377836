import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomColorInput from "../../ui/custom-color-input";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue(),
  seo_description: hasValue(),
  color_base: hasValue()
});

const CategoryCreate = () =>
{
  return (
    <Create
      title="Nova Categoria"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput source="seo_description" label="Descrição para o SEO" fullWidth required />
        <TextInput source="youtube_playlist_id" label="Youtube Playlist Id" fullWidth />
        <CustomColorInput source="color_base" label="Cor Base" isRequired />
      </SimpleForm>
    </Create>
  )
}

export default CategoryCreate;
