import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonOpenLink from '../../ui/button-open-link';
import PrivacyPolicyExporter from '../../exporter/privacy-policy-exporter';

const PrivacyPolicyList = (props) => (
  <List
    title="Políticas de Privacidade"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ PrivacyPolicyExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <ButtonOpenLink source="secure_url" text="Ver" label="Arquivo" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
);

export default PrivacyPolicyList;
