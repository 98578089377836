import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = codesofconduct =>
{
  const data = codesofconduct.map(codeofconduct => ({
    id: codeofconduct.id,
    url: codeofconduct.secure_url,
    criado_em: codeofconduct.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'url', 'criado_em']
  });
  downloadCSV(csv, 'codeofconduct');
}

export default exporter;
