import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterQuestion from '../../filter/custom-filter-question';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonEdit from '../../ui/button-edit';
import ButtonDetails from '../../ui/button-details';
import QuestionAlternativeField from '../../ui/question-alternatives-field';
import QuestionExporter from '../../exporter/question-exporter';

const QuestionList = (props) => (
  <List
    title="Questões"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterQuestion }
    exporter={ QuestionExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <ChipField source="quiz_title" label="Quiz" sortable={false}/>
      <TextField source="title" label="Título" sortable={false}/>
      <QuestionAlternativeField label="Alternativas" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default QuestionList;
