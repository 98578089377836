import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import PromotionExporter from '../../exporter/promotion-exporter';

const PromotionList = (props) => (
  <List
    title="Promoções"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ PromotionExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <CustomImageField source="image_cover" label="Imagem" sortable={false}/>
      <TextField source="title" label="Título" sortable={false}/>
      <TextField source="description" label="Descrição" sortable={false}/>
      <TextField source="number_winners" label="Número de Ganhadores" sortable={false} />
      <TextField source="count_participants" label="Participantes" sortable={false} />
      <TextField source="init_at_format" label="Início" sortable={false}/>
      <TextField source="end_at_format" label="Fim" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default PromotionList;
