import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = users =>
{
  const data = users.map(user => ({
    id: user.id,
    nome: user.profile.name,
    email: user.email,
    username: user.username,
    data_nascimento: user.profile.birthdate,
    telefone: user.profile.phone,
    endereco: user.address.address,
    cep: user.address.zip_code,
    bairro: user.address.neighborhood,
    estado: user.address.state,
    cidade: user.address.city,
    criado_em: user.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'email', 'username', 'data_nascimento', 'telefone',
             'endereco', 'cep', 'bairro', 'estado', 'cidade', 'criado_em']
  });
  downloadCSV(csv, 'users');
}

export default exporter;
