import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = newsList =>
{
  const data = newsList.map(news => ({
    id: news.id,
    categoria: news.subcategory.category.name,
    subcategoria: news.subcategory.name,
    titulo: news.title,
    texto: news.text,
    descricao: news.description,
    data: news.news_date_format,
    assinada_por: news.signed_by,
    criado_em: news.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'categoria', 'subcategoria', 'titulo', 'texto', 'descricao',
             'data', 'assinada_por', 'criado_em']
  });
  downloadCSV(csv, 'news');
}

export default exporter;
