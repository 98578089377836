import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = programs =>
{
  const data = programs.map(program => ({
    id: program.id,
    categoria: program.category.name,
    nome: program.name,
    descricao: program.description,
    playlist: program.dailymotion_playlist_id,
    inicio: program.init_at_format,
    fim: program.end_at_format,
    dias: program.days,
    apresentadores: program.presenters_name,
    criado_em: program.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'categoria', 'nome', 'descricao', 'playlist', 'inicio', 'fim',
             'dias', 'apresentadores', 'criado_em']
  });
  downloadCSV(csv, 'programs');
}

export default exporter;
