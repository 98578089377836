import React from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  NumberInput,
  DateInput,
  DateTimeInput,
  BooleanInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from "react-admin";
import { selectOneOption, number, hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomRichTextInput from "../../ui/custom-rich-text-input";
import CustomImageField from '../../ui/custom-image-field';
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  subcategory_id: hasValue(),
  image_cover_credits: hasValue(),
  title: hasValue(),
  text: Yup.string().required("Obrigatório"),
  signed_by: hasValue(),
  date_news: hasValue(),
  publication_date: hasValue(),
  post_types: Yup.array().nullable().of(Yup.object().shape(
  {
    type_news_post_type: selectOneOption(),
    paragraph: number(),
    text_button: hasValue(),
    link: hasValue(),
    title: Yup.string()
      .nullable()
      .when("type_news_post_type", { is: 0, then: () => hasValue() }),
    image_thumb: Yup.mixed()
      .nullable()
      .when(["type_news_post_type", "id"], {
        is: (type_news_post_type, id) => type_news_post_type === 0 && id === null, then: () => file() }),
    informations: Yup.array()
      .nullable()
      .when("type_news_post_type", {
        is: 0,
        then: () =>
          Yup.array()
            .of(Yup.object().shape({ text: hasValue() }))
            .required("Obrigatório")
            .min(1, "Obrigatório")
      })
  }))
});

const optionRenderer = (subcategory) => `${subcategory.category.name} - ${subcategory.name}`;

const NewsEdit = () =>
{
  return (
    <Edit title={<EditTitle subtitle="Editar Notícia: " source="title" />} mutationMode="pessimistic">
      <TabbedForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}>
        <TabbedForm.Tab label="Dados">
          <TextInput source="id" label="ID" disabled />
          <ReferenceInput source="subcategory_id" perPage={100} reference="subcategory" >
            <SelectInput label="Subcategoria" optionText={optionRenderer} optionValue="id" fullWidth required />
          </ReferenceInput>
          <TextInput multiline source="title" label="Título" fullWidth required />
          <TextInput source="description" label="Descrição" fullWidth required />
          <TextInput source="signed_by" label="Assinada por" fullWidth required />
          <TextInput source="seo_description" label="Descrição para o SEO" fullWidth />
          <DateInput source="date_news" label="Data da Notícia" parse={(date) => (date ? date.toISOString() : null)} required />
          <DateTimeInput source="publication_date" label="Data de Publicação" required />
          <DateTimeInput source="featured_limit_date" label="Em Destaque até" />
          <BooleanInput source="active" label="Ativo" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Imagens">
          <CustomImageField source="image_cover" label="Imagem Cover" sortable={false}/>
          <br/>
          <ImageInput source="image_cover" label="Imagem Cover (1200x630) Max: 5MB" maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg" >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="image_cover_credits" label="Créditos Imagem Cover" fullWidth required />
          <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
          <br/>
          <ImageInput source="image_thumb" label="Imagem Thumb (400x400) Max: 5MB" maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg" >
            <ImageField source="src" title="title" />
          </ImageInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Texto">
          <CustomRichTextInput source="text" label="Texto" fullWidth isRequired />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Post Type(s)">
          <ArrayInput source="post_types" label="Post Type(s)">
            <SimpleFormIterator disableReordering disableClear fullWidth >
              <br/>
              <ReferenceInput source="type_news_post_type" reference="typenewsposttype">
                <SelectInput label="Tipo de Post Type" optionText="name" optionValue="type" fullWidth isRequired />
              </ReferenceInput>
              <FormDataConsumer>
              {
                ({ formData, scopedFormData }) =>
                scopedFormData &&
                scopedFormData.type_news_post_type !== null &&
                scopedFormData.type_news_post_type === 0 ? (
                  <>
                    <NumberInput source={'paragraph'} fullWidth label="Parágrafo" isRequired />
                    <TextInput source={'title'} fullWidth label="Título" isRequired />
                    <TextInput source={'text_button'} fullWidth label="Texto do Botão" isRequired />
                    <TextInput source={'link'} fullWidth label="Link" isRequired />
                    <ImageInput source={'image_thumb'} label="Imagem Thumb (600x600)  Max: 5MB" isRequired maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg">
                      <ImageField source="src" title="title" />
                    </ImageInput>
                    <ArrayInput source={'informations'} label="Informações" isRequired>
                      <SimpleFormIterator disableReordering disableClear fullWidth >
                        <br/>
                        <TextInput source="text" label="Texto" fullWidth />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </>
                ) :
                scopedFormData &&
                scopedFormData.type_news_post_type != null &&
                scopedFormData.type_news_post_type === 1 ? (
                  <>
                    <NumberInput source={'paragraph'} fullWidth label="Parágrafo" isRequired />
                    <TextInput source={'text_button'} fullWidth label="Texto do Botão" isRequired />
                    <TextInput source={'link'} fullWidth label="Link" isRequired />
                  </>
                ) : null
              }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default NewsEdit;
