import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = instaReels =>
{
  const data = instaReels.map(instaReelsObj => ({
    id: instaReelsObj.id,
    subcategoria: instaReelsObj.subcategory.name,
    link: (instaReelsObj.embed_text.match(/\bhttps?:\/\/\S+/gi) != null ?
           instaReelsObj.embed_text.match(/\bhttps?:\/\/\S+/gi)[0].replace('"', '') : ''),
    criado_em: instaReelsObj.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'subcategoria', 'link', 'criado_em']
  });
  downloadCSV(csv, 'instaReels');
}

export default exporter;
