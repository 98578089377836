import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterQuiz from '../../filter/custom-filter-quiz';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import QuizExporter from '../../exporter/quiz-exporter';
import QuizQuestionField from '../../ui/quiz-question-field';

const QuizList = (props) => (
  <List
    title="Quizzes"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterQuiz }
    exporter={ QuizExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <ChipField source="promotion_quiz_title" label="Promoção" sortable={false}/>
      <TextField source="title" label="Título" sortable={false}/>
      <TextField source="init_at_format" label="Início" sortable={false} />
      <TextField source="end_at_format" label="Fim" sortable={false}/>
      <QuizQuestionField label="Questões" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default QuizList;
