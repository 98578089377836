import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  program_id: hasValue(),
});

const FileEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Arquivo: " source="name" />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="name" label="Nome" disabled />
        <ReferenceInput source="program_id" perPage={100} reference="program">
          <SelectInput
            label="Programa"
            optionText="name"
            optionValue="id"
            fullWidth
            required
          />
        </ReferenceInput>
        <FileInput
          source="file"
          label="Arquivo"
          accept="application/*,video/*,audio/*,image/*"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ImageInput
          source="image"
          label="Imagem (200x200) Max: 2MB"
          maxSize={2000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default FileEdit;
