import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = teams =>
{
  const data = teams.map(team => ({
    id: team.id,
    nome: team.name,
    criado_em: team.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'teams');
}

export default exporter;
