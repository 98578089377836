import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  name: hasValue(),
  position: hasValue(),
});

const BrandEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Marca: " source="name" />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="name" label="Nome" fullWidth required />
        <NumberInput source="position" label="Posição" fullWidth required />
        <ImageInput
          source="image"
          label="Imagem (300x300) Max: 2MB"
          maxSize={2000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default BrandEdit;
