import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonEdit from '../../ui/button-edit';
import CategoryExporter from '../../exporter/category-exporter';
import { ColorField } from 'react-admin-color-picker';

const CategoryList = (props) => (
  <List
    title="Categorias"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ CategoryExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" label="Id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="seo_description" label="Descrição para o SEO" sortable={false} />
      <ColorField source="color_base" label="Cor Base" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default CategoryList;
