import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  TimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  category_id: hasValue(),
  dailymotion_playlist_id: hasValue(),
  name: hasValue(),
  description: hasValue(),
  presenters_id: hasValue(),
  days_group_type: hasValue(),
  init_at: hasValue(),
  end_at: hasValue(),
  image: file(),
});

const ProgramCreate = () => {
  return (
    <Create title="Novo Programa" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <ReferenceInput source="category_id" perPage={100} reference="category">
          <SelectInput
            label="Categoria"
            optionText="name"
            optionValue="id"
            fullWidth
            required
          />
        </ReferenceInput>
        <TextInput
          source="dailymotion_playlist_id"
          label="Dailymotion Playlist Id"
          fullWidth
          required
        />
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput
          multiline
          source="description"
          label="Descrição"
          fullWidth
          required
        />
        <ReferenceArrayInput source="presenters_id" reference="presenter">
          <SelectArrayInput
            label="Apresentador(es)"
            optionText="name"
            optionValue="id"
            fullWidth
            required
          />
        </ReferenceArrayInput>
        <ReferenceInput source="days_group_type" reference="program/daysgroup">
          <SelectInput
            label="Dia(s)"
            optionText="name"
            optionValue="type"
            fullWidth
            required
          />
        </ReferenceInput>
        <TimeInput source="init_at" label="Hora de Início" required />
        <TimeInput source="end_at" label="Hora de Término" required />
        <ImageInput
          source="image"
          label="Imagem (Aspect Ratio 16:9, Ex: 600x337) Max: 5MB"
          maxSize={5000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
          required
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ProgramCreate;
