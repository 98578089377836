import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = genders =>
{
  const data = genders.map(gender => ({
    id: gender.id,
    nome: gender.name,
    criado_em: gender.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'criado_em']
  });
  downloadCSV(csv, 'genders');
}

export default exporter;
