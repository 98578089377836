import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = chatbanned =>
{
  const data = chatbanned.map(chatban => ({
    id: chatban.id,
    status: chatban.status_chat_ban_name,
    denunciado: chatban.chat_denounce.denounced.profile.name + ' - ' + chatban.chat_denounce.denounced.email,
    mensagem: chatban.chat_denounce.message,
    banido_em: chatban.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'status', 'denunciado', 'mensagem', 'banido_em']
  });
  downloadCSV(csv, 'chatbanned');
}

export default exporter;
