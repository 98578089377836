import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterPhone from '../../filter/custom-filter-phone';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonEdit from '../../ui/button-edit';
import PhoneExporter from '../../exporter/phone-exporter';

const PhoneList = (props) => (
  <List
    title="Telefones"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterPhone }
    exporter={ PhoneExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" label="Id" sortable={false} />
      <ChipField source="type_phone_name" label="Tipo de Telefone" sortable={false} />
      <TextField source="phone" label="Telefone" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default PhoneList;
