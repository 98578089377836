import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  subcategory_id: hasValue(),
  url: hasValue()
});

const optionRenderer = (subcategory) => `${subcategory.category.name} - ${subcategory.name}`;

const InstaReelsCreate = () => {
  return (
    <Create title="Novo Reels do Instagram" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}>
        <ReferenceInput source="subcategory_id" perPage={100} reference="subcategory">
          <SelectInput label="Subcategoria" optionText={optionRenderer} optionValue="id" fullWidth required />
        </ReferenceInput>
        <TextInput source="url" label="Url" fullWidth required />
      </SimpleForm>
    </Create>
  );
};

export default InstaReelsCreate;
