import React from "react";
import {
  Create,
  TabbedForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomRichTextInput from "../../ui/custom-rich-text-input";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  columnist_id: hasValue(),
  subcategory_id: hasValue(),
  image_cover: file(),
  image_cover_credits: hasValue(),
  title: hasValue(),
  text: Yup.string().required("Obrigatório"),
  date_news: hasValue(),
  publication_date: hasValue(),
  image_thumb: file(),
});

const optionRenderer = (subcategory) => `${subcategory.category.name} - ${subcategory.name}`;

const ArticleCreate = () => {
  return (
    <Create title="Novo Artigo" redirect="list">
      <TabbedForm noValidate resolver={yupResolver(Schema)} toolbar={<CustomSaveToolbar />}>
        <TabbedForm.Tab label="Dados">
          <ReferenceInput source="columnist_id" sort={{ field: "name", order: "ASC" }} perPage={100} reference="columnist">
            <SelectInput label="Colunista" optionText="name" optionValue="id" fullWidth required />
          </ReferenceInput>
          <ReferenceInput source="subcategory_id" perPage={100} reference="subcategory">
            <SelectInput label="Subcategoria" optionText={optionRenderer} optionValue="id" fullWidth required />
          </ReferenceInput>
          <TextInput multiline source="title" fullWidth label="Título" required />
          <TextInput source="description" fullWidth label="Descrição" />
          <TextInput source="seo_description" label="Descrição para o SEO" fullWidth />
          <DateInput source="date_news" label="Data do Artigo" parse={(date) => (date ? date.toISOString() : null)} required />
          <DateTimeInput source="publication_date" label="Data de Publicação" required />
          <DateTimeInput source="featured_limit_date" label="Em Destaque até" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Imagens">
          <ImageInput source="image_cover" label="Imagem Cover (1200x630) Max: 5MB" maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg" required>
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="image_cover_credits" fullWidth label="Créditos Imagem Cover" />
          <ImageInput source="image_thumb" label="Imagem Thumb (400x400) Max: 5MB" maxSize={5000000} accept="image/png,image/gif,image/jpeg,image/jpg" required>
            <ImageField source="src" title="title" />
          </ImageInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Texto">
          <CustomRichTextInput source="text" label="Texto" fullWidth isRequired />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default ArticleCreate;
