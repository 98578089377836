import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = promotionsquiz =>
{
  const data = promotionsquiz.map(promotionquiz => ({
    id: promotionquiz.id,
    titulo: promotionquiz.title,
    criado_em: promotionquiz.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'titulo', 'criado_em']
  });
  downloadCSV(csv, 'promotionsquiz');
}

export default exporter;
