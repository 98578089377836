import React from 'react';
import {
    Show,
    TextField,
    DateField,
    RichTextField,
    SimpleShowLayout
} from 'react-admin';
import CustomImageField from '../../ui/custom-image-field';

const PromotionShow = () => (
  <Show
    title="Promoção">
    <SimpleShowLayout>
      <CustomImageField source="image_cover" label="Imagem" sortable={false}/>
      <TextField source="id" />
      <TextField source="title" label="Título"/>
      <TextField source="description" label="Descrição"/>
      <TextField source="number_winners" label="Número de Ganhadores" sortable={false} />
      <TextField source="count_participants" label="Participantes" sortable={false} />
      <TextField source="init_at_format" label="Início" sortable={false}/>
      <TextField source="end_at_format" label="Fim" sortable={false}/>
      <TextField source="external_url" label="Url Externa" sortable={false}/>
      <RichTextField source="regulation" label="Regulamento"/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" />
      <DateField source="updated_at" locales="pt-BR" label="Atualizado em" />
    </SimpleShowLayout>
  </Show>
);

export default PromotionShow;
