import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    BooleanField
} from 'react-admin';
import CustomImageField from '../../ui/custom-image-field';
import PresenterField from '../../ui/presenter-field';

const ProgramShow = () => (
  <Show
    title="Programa">
    <SimpleShowLayout>
      <CustomImageField source="image_cover" label="Imagem" sortable={false}/>
      <TextField source="id" />
      <TextField source="category.name" label="Categoria"/>
      <TextField source="dailymotion_playlist_id" label="Playlist Id"/>
      <TextField source="name" label="Nome"/>
      <TextField source="description" label="Descrição"/>
      <TextField source="days_group.name" label="Dia(s)" sortable={false}/>
      <TextField source="init_at_format" label="Início"/>
      <TextField source="end_at_format" label="Fim"/>
      <PresenterField label="Apresentadores"/>
      <DateField source="created_at" locales="pt-BR" label="Criado em"/>
      <DateField source="updated_at" locales="pt-BR" label="Atualizado em"/>
      <BooleanField source="active"/>
    </SimpleShowLayout>
  </Show>
);

export default ProgramShow;
