import React from 'react';
import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const styles =
{
  main:
  {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 }
};

const QuestionAlternativeField = ({ source }) =>
{
  const record = useRecordContext();
  return (
    <span style={styles.main}>
    {
      record.alternatives &&
        record.alternatives.map(alternative => (
            <Chip
              key={alternative.id}
              style={styles.chip}
              label={alternative.text + ' - ' + (alternative.is_correct === true ? 'Correta' : 'Errada')}
            />
        ))
    }
    </span>
  );
};

QuestionAlternativeField.defaultProps =
{
  addLabel: true,
  source: 'Alternativas'
};

export default QuestionAlternativeField;
