import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = buttonsMenu =>
{
  const data = buttonsMenu.map(buttonMenu => ({
    id: buttonMenu.id,
    tipo: buttonMenu.type_button_menu_name,
    link: buttonMenu.link,
    texto: buttonMenu.text,
    cor_background: buttonMenu.color_background,
    cor_texto: buttonMenu.color_font,
    criado_em: buttonMenu.created_at_format
  }));
  const csv = convertToCSV({
      data,
      fields: ['id', 'tipo', 'link', 'texto', 'cor_background', 'cor_texto', 'criado_em']
  });
  downloadCSV(csv, 'buttonsmenu');
}

export default exporter;
