import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  subcategory_id: hasValue(),
  url: hasValue()
});

const optionRenderer = (subcategory) => `${subcategory.category.name} - ${subcategory.name}`;

const InstaReelsEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Reels do Instagram" source="name" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}>
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput source="subcategory_id" perPage={100} reference="subcategory">
          <SelectInput label="Subcategoria" optionText={optionRenderer} optionValue="id" fullWidth required />
        </ReferenceInput>
        <TextInput source="url" label="Url" fullWidth required />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default InstaReelsEdit;
