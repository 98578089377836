import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  name: hasValue(),
  instagram: hasValue(),
});

const PresenterEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Apresentador: " source="name" />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput source="instagram" label="Instagram" fullWidth required />
        <ImageInput
          source="image"
          label="Thumb (100x100) Max: 1MB"
          maxSize={1000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default PresenterEdit;
