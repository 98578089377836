import { ColorInput } from "react-admin-color-picker";
import { useInput } from "react-admin";
import { useRef } from "react";

const CustomColorInput = (props) =>
{
  var input = useRef(null);
  const { onChange, onBlur, ...rest } = props;

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ onChange, onBlur, ...props });

  const showError = (isTouched || isSubmitted) && invalid;

  return (
    <ColorInput
      {...field}
      ref={input}
      inputRef={(e) => (input = e)}
      isRequired={isRequired}
      helperText={showError ? error?.message : ""}
      variant="outlined"
      {...rest}
    />
  );
};

export default CustomColorInput;
