import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = quizes =>
{
  const data = quizes.map(quiz => ({
    id: quiz.id,
    titulo: quiz.title,
    inicio: quiz.init_at_format,
    fim: quiz.end_at_format,
    questoes: quiz.questions_name,
    criado_em: quiz.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'titulo', 'inicio', 'fim', 'questoes', 'criado_em']
  });
  downloadCSV(csv, 'quizzes');
}

export default exporter;
