import React from 'react';
import {
  Edit,
  TextInput,
  PasswordInput,
  SimpleForm,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import {
  hasValue,
  leastOneOption
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue(),
  roles_id: leastOneOption()
});

const AdminEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Usuário Admin: " source="name" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="Id" disabled />
        <TextInput source="email" label="E-mail" fullWidth disabled />
        <TextInput source="username" label="Username" fullWidth disabled />
        <TextInput source="name" label="Nome" fullWidth required />
        <PasswordInput source="password" fullWidth label="Senha" />
        <ReferenceArrayInput source="roles_id" sort={{ field: 'name', order: 'ASC' }} reference="admin/roles">
          <SelectArrayInput label="Permissão(ões)" optionText="name" optionValue="id" fullWidth required />
        </ReferenceArrayInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default AdminEdit;
