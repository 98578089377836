import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterNews from '../../filter/custom-filter-news';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import NewsExporter from '../../exporter/news-exporter';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';

const optionRenderer = news => `${news.subcategory.category.name} - ${news.subcategory.name}`;

const NewsList = (props) => (
  <List
    title="Notícias"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterNews }
    exporter={ NewsExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
      <FunctionField render={optionRenderer} label="Subcategoria"/>
      <TextField source="title" label="Título"/>
      <TextField source="news_date_format" label="Data da Notícia"/>
      <TextField source="created_by.profile.name" label="Criada Por" sortable={false}/>
      <BooleanField source="featured" label="Destaque" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em"/>
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default NewsList;
