import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterQuestion = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Quiz" source="quiz_id" reference="quiz" alwaysOn >
    <SelectInput label="Quiz" optionText="title" optionValue="id" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterQuestion;
