import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  quiz_id: hasValue(),
  title: hasValue(),
  alternative_correct: hasValue(),
  alternative_incorrect_1: hasValue()
});

const QuestionEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Questão: " source="title" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput source="quiz_id" reference="quiz">
          <SelectInput label="Quiz" optionText="title" optionValue="id" fullWidth required />
        </ReferenceInput>
        <TextInput source="title" label="Título" fullWidth required />
        <TextInput source="alternative_correct" label="Alternativa Correta" fullWidth required />
        <TextInput source="alternative_incorrect_1" label="Alternativa Errada 1" fullWidth required />
        <TextInput source="alternative_incorrect_2" label="Alternativa Errada 2" fullWidth />
        <TextInput source="alternative_incorrect_3" label="Alternativa Errada 3" fullWidth />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default QuestionEdit;
