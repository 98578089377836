import React from 'react';
import {
  Show,
  TextField,
  SimpleShowLayout
} from 'react-admin';
import QuestionAlternativeField from '../../ui/question-alternatives-field';

const QuestionShow = () => (
  <Show
    title="Enquete">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="quiz_title" label="Quiz"/>
      <TextField source="title" label="Título"/>
      <QuestionAlternativeField label="Alternativas" sortable={false}/>
    </SimpleShowLayout>
  </Show>
);

export default QuestionShow;
