import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = phones =>
{
  const data = phones.map(phone => ({
    id: phone.id,
    tipo: phone.type_phone_name,
    telefone: phone.phone,
    criado_em: phone.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'tipo', 'telefone', 'criado_em']
  });
  downloadCSV(csv, 'phones');
}

export default exporter;
