import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const CustomFilterPromotionQuizRanking = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Quiz" source="promotion_quiz_id" reference="promotionquiz" alwaysOn >
    <SelectInput label="Quiz" optionText="title" optionValue="id" />
  </ReferenceInput>,
  <DateInput source="init_at" label="Data de Início" alwaysOn  />,
  <DateInput source="end_at" label="Data de Término" alwaysOn />
];

export default CustomFilterPromotionQuizRanking;
