import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const optionRenderer = subcategory => `${subcategory.category.name} - ${subcategory.name}`;

const CustomFilterNews = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Subcategoria" source="subcategory_id" reference="subcategory" perPage={100} alwaysOn >
    <SelectInput label="Subcategoria" optionText={optionRenderer} optionValue="id" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterNews;
