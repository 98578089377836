import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue()
});

const TeamCreate = () =>
{
  return (
    <Create
      title="Novo Time"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="name" label="Nome" fullWidth required />
      </SimpleForm>
    </Create>
  )
}

export default TeamCreate;
