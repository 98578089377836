import React from 'react';
import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const styles =
{
  main:
  {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 }
};

const CategoriesField = ({ source }) =>
{
  const record = useRecordContext();
    return (
      <span style={styles.main}>
      {
        record.categories &&
          record.categories.map(category => (
            <Chip
              key={category.id}
              style={styles.chip}
              label={category.name}
            />
          ))
      }
      </span>
    );
};

CategoriesField.defaultProps =
{
  addLabel: true,
  source: 'Categorias'
};

export default CategoriesField;
