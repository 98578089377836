import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = columnists =>
{
  const data = columnists.map(columnist => ({
    id: columnist.id,
    nome: columnist.name,
    genero: columnist.gender_name,
    descricao: columnist.description,
    categorias: columnist.categories_name,
    criado_em: columnist.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'genero', 'descricao', 'categorias', 'criado_em']
  });
  downloadCSV(csv, 'columnists');
}

export default exporter;
