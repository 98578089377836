import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import ButtonEdit from '../../ui/button-edit';
import CategoriesField from '../../ui/categories-field';
import ColumnistExporter from '../../exporter/columnist-exporter';

const ColumnistList = (props) => (
  <List
    title="Colunistas"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ ColumnistExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="gender_name" label="Gênero" sortable={false} />
      <CategoriesField label="Categorias" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ColumnistList;
