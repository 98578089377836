import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonEdit from '../../ui/button-edit';
import LiveEventExporter from '../../exporter/live-event-exporter';

const LiveEventList = (props) => (
  <List
    title="Eventos da Live"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ LiveEventExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false}/>
      <TextField source="description" label="Descrição" sortable={false}/>
      <TextField source="event_init_date_format" label="Data de Início" sortable={false}/>
      <TextField source="event_end_date_format" label="Data de Término" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default LiveEventList;
