import {
  Button,
  useRecordContext,
  useUpdate
} from "react-admin";
import IconRemove from "@mui/icons-material/RemoveCircleOutline";

function ButtonRemoveBan()
{
  const record = useRecordContext();
  const handleClick = (e) => { e.stopPropagation(); update(); }
  const [update, { isLoading }] = useUpdate('chatBan',
    { id: record.id, data: { status_chat_ban: 1 }, previousData: record });

  if (record.status_chat_ban === 0)
    return (
      <Button onClick={handleClick} disabled={isLoading} label="Remover">
        <IconRemove />
      </Button>
    );
  else
    return null;
}

export default ButtonRemoveBan;
