import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = termsofuses =>
{
  const data = termsofuses.map(termsofuse => ({
    id: termsofuse.id,
    url: termsofuse.secure_url,
    criado_em: termsofuse.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'url', 'criado_em']
  });
  downloadCSV(csv, 'termsofuse');
}

export default exporter;
