import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterPhone = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo" source="type_phone" reference="phone/types" alwaysOn >
    <SelectInput label="Tipo" optionText="name" optionValue="type" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterPhone;
