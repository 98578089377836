import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  type_phone: hasValue(),
  phone: hasValue()
});

const PhoneEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Telefone: " source="phone" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput source="type_phone" reference="phone/types">
          <SelectInput label="Tipo de Telefone" optionText="name" optionValue="type" fullWidth required />
        </ReferenceInput>
        <TextInput source="phone" label="Telefone" fullWidth required />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default PhoneEdit;
