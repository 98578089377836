import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = files =>
{
  const data = files.map(file => ({
    id: file.id,
    name: file.name,
    programa: file.program.name,
    url: file.url_file,
    criado_em: file.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'name', 'programa', 'url', 'criado_em']
  });
  downloadCSV(csv, 'files');
}

export default exporter;
