import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import CustomColorInput from "../../ui/custom-color-input";

const Schema = Yup.object({
  type_button_menu: hasValue(),
  link: hasValue(),
  text: Yup.string().when("type_button_menu", {
    is: 0,
    then: () => Yup.string().required("Obrigatório"),
  }),
  color_background: Yup.string().when("type_button_menu", {
    is: 0,
    then: () => Yup.string().required("Obrigatório"),
  }),
  color_font: Yup.string().when("type_button_menu", {
    is: 0,
    then: () => Yup.string().required("Obrigatório"),
  }),
  image: Yup.string()
    .nullable()
    .when("type_button_menu", {
      is: 1,
      then: () => file(),
    }),
});

const ButtonMenuCreate = () => {
  return (
    <Create title="Novo Botão do Menu" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <ReferenceInput source="type_button_menu" reference="buttonmenu/types">
          <SelectInput
            label="Tipo de Botão"
            optionText="name"
            optionValue="type"
            fullWidth
            required
          />
        </ReferenceInput>
        <TextInput source="link" label="Link" type="url" fullWidth required />
        <FormDataConsumer>
          {({ formData }) =>
            formData &&
            formData.type_button_menu != null &&
            formData.type_button_menu === 0 ? (
              <>
                <TextInput source="text" label="Texto" fullWidth required />
                <CustomColorInput
                  source="color_background"
                  label="Cor de Background"
                  isRequired
                />
                <CustomColorInput
                  source="color_font"
                  label="Cor da Fonte"
                  isRequired
                />
              </>
            ) : formData &&
              formData.type_button_menu != null &&
              formData.type_button_menu === 1 ? (
              <ImageInput
                source="image"
                label="Imagem (128x64) Max: 1MB"
                maxSize={1000000}
                accept="image/png,image/gif,image/jpeg,image/jpg"
                required
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default ButtonMenuCreate;
