import {
  Button,
  useRecordContext,
  useResourceContext,
  useRedirect,
} from "react-admin";
import IconEdit from "@mui/icons-material/Edit";

function ButtonEdit()
{
  const record = useRecordContext();
  const resource = useResourceContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    redirect("/" + resource + "/" + record.id);
  };

  return (
    <Button onClick={btnClick} label="Editar">
      <IconEdit />
    </Button>
  );
}

export default ButtonEdit;
