import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from 'react-admin';
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const UserEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Usuário Ouvinte: " source="username" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="Id" disabled />
        <TextInput source="profile.name" label="Nome" fullWidth disabled />
        <TextInput source="email" label="E-mail" fullWidth disabled />
        <TextInput source="username" label="Username" fullWidth disabled />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit;
