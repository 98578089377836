import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterUrl = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo de URL" source="type_url" reference="url/types" alwaysOn >
    <SelectInput label="Tipo de URL" optionText="name" optionValue="type" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterUrl;
