import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
} from "react-admin";
import { hasValue, file } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  program_id: hasValue(),
  name: hasValue(),
  file: file(),
  image: file(),
});

const FileCreate = () => {
  return (
    <Create title="Novo Arquivo para Download" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <ReferenceInput source="program_id" perPage={100} reference="program">
          <SelectInput
            label="Programa"
            optionText="name"
            optionValue="id"
            fullWidth
            required
          />
        </ReferenceInput>
        <TextInput source="name" label="Nome" fullWidth required />
        <FileInput
          source="file"
          label="Arquivo"
          accept="application/*,video/*,audio/*,image/*"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ImageInput
          source="image"
          label="Thumb (200x200) Max: 2MB"
          maxSize={2000000}
          accept="image/png,image/gif,image/jpeg,image/jpg"
          required
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default FileCreate;
