import React from 'react';
import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const styles =
{
  main:
  {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  chip: { margin: 4 }
};

const SurveyOptionField = ({ source }) =>
{
  const record = useRecordContext();
  return (
    <span style={styles.main}>
    {
      record.options &&
        record.options.map(option => (
            <Chip
              key={option.id}
              style={styles.chip}
              label={option.text + ' - ' + option.percent_votes + '%'}
            />
        ))
    }
    </span>
  );
};

SurveyOptionField.defaultProps =
{
  addLabel: true,
  source: 'Opções'
};

export default SurveyOptionField;
