import React from 'react';
import {
  Show,
  TextField,
  DateField,
  SimpleShowLayout,
  BooleanField
} from 'react-admin';
import LinkField from '../../ui/link-field';

const InstaReelsShow = () => (
  <Show
    title="Reels do Intagram">
    <SimpleShowLayout>
      <TextField source="id" sortable={false} />
      <TextField source="subcategory.name" label="Subcategoria" sortable={false}/>
      <LinkField source="url" label="Url" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </SimpleShowLayout>
  </Show>
);

export default InstaReelsShow;
