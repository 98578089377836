import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import SurveyExporter from '../../exporter/survey-exporter';
import SurveyOptionsField from '../../ui/survey-options-field';

const SurveyList = (props) => (
  <List
    title="Enquetes"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ SurveyExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <ChipField source="program.name" label="Programa" sortable={false}/>
      <TextField source="question" label="Questão" sortable={false}/>
      <TextField source="total_votes" label="Votos" sortable={false} />
      <TextField source="init_at_format" label="Início" sortable={false}/>
      <TextField source="end_at_format" label="Fim" sortable={false}/>
      <SurveyOptionsField label="Opções" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default SurveyList;
