var env = process.env.REACT_APP_NODE_ENV || "development";

var config =
{
  development:
  {
    api:
    {
      api_key: "9a3532c3b41547ec88c91c9c120d90f2",
      url: "https://dev-api.98live.com.br"
    },
    local_storage:
    {
      user: "user_admin_98_live",
      auth: "auth_admin_98_live"
    },
    jwt:
    {
      key: "46b98b0b1e7be0d19e17ab3a14114399"
    },
    permissions:
    {
      admin_users_admin: "ff3b8b5fbec2a4e7716e0a808b5cb295",
      admin_customers: "df62bac6483be9fc7e52cfb50ee06377",
      admin_phones: "7ab8b8c978d29cbe6bdc22fbc4067f47",
      admin_urls: "6904779f87f78ce37d3521a747b8a28d",
      admin_teams: "89b6b0f957fc787c095790f0e193bc41",
      admin_banners: "c34e62f02268d512dd4f08e1204ed25c",
      admin_promotions: "9e560f0732dfc87cc284e1cd95d11c50",
      admin_programs: "be3a5035b25a42522148f3be5f1c3e3e",
      admin_surveys: "6287251453651cfc9bc74f7a702914f5",
      admin_news: "312f2e37c7a9b7018c645e86f73614cc",
      admin_archives: "4248caf9016e7ebed318c6a92b2c8882",
      admin_file_download: "07a7fce84111c5551d848de063f7aa87",
      admin_promotion_quiz: "fa93884573006910c3f31167b60f594b",
      admin_chat: "c63a7dbfae3d924d781779440245ce9d",
      admin_button_menu: "93ee7370068c6b529ee5d2ecd2c95bfb",
      admin_brands: "ebeb4494a6c013e0a54de7109128d63c",
      admin_columnist: "b7f84951bbb886668130d09594f7fe8d",
      admin_categories: "bc891a1769f5d1e085584425f6c1eaa6",
      admin_articles: "abb87c45f41889a62f28ece2b07b440d",
      admin_podcasts: "763ee0ab73e21331d1c62f7ead28ea92",
      admin_live_events: "327fad2e393e81ad714c04df92eb0dc9",
      admin_genders: "2d8da6943015a52bbd758cf6fb547f22",
      admin_insta_reels: "4247fa2446cdab8bfe3cfec159ac2f58"
    }
  },
  production:
  {
    api:
    {
      api_key: "9a3532c3b41547ec88c91c9c120d90f2",
      url: "https://api.98live.com.br"
    },
    local_storage:
    {
      user: "user_admin_98_live",
      auth: "auth_admin_98_live"
    },
    jwt:
    {
      key: "46b98b0b1e7be0d19e17ab3a14114399"
    },
    permissions:
    {
      admin_users_admin: "ff3b8b5fbec2a4e7716e0a808b5cb295",
      admin_customers: "df62bac6483be9fc7e52cfb50ee06377",
      admin_phones: "7ab8b8c978d29cbe6bdc22fbc4067f47",
      admin_urls: "6904779f87f78ce37d3521a747b8a28d",
      admin_teams: "89b6b0f957fc787c095790f0e193bc41",
      admin_banners: "c34e62f02268d512dd4f08e1204ed25c",
      admin_promotions: "9e560f0732dfc87cc284e1cd95d11c50",
      admin_programs: "be3a5035b25a42522148f3be5f1c3e3e",
      admin_surveys: "6287251453651cfc9bc74f7a702914f5",
      admin_news: "312f2e37c7a9b7018c645e86f73614cc",
      admin_archives: "4248caf9016e7ebed318c6a92b2c8882",
      admin_file_download: "07a7fce84111c5551d848de063f7aa87",
      admin_promotion_quiz: "fa93884573006910c3f31167b60f594b",
      admin_chat: "c63a7dbfae3d924d781779440245ce9d",
      admin_button_menu: "93ee7370068c6b529ee5d2ecd2c95bfb",
      admin_brands: "ebeb4494a6c013e0a54de7109128d63c",
      admin_columnist: "b7f84951bbb886668130d09594f7fe8d",
      admin_categories: "bc891a1769f5d1e085584425f6c1eaa6",
      admin_articles: "abb87c45f41889a62f28ece2b07b440d",
      admin_podcasts: "763ee0ab73e21331d1c62f7ead28ea92",
      admin_live_events: "327fad2e393e81ad714c04df92eb0dc9",
      admin_genders: "2d8da6943015a52bbd758cf6fb547f22",
      admin_insta_reels: "4247fa2446cdab8bfe3cfec159ac2f58"
    }
  }
};

module.exports = config[env];
