import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField
} from 'react-admin';
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import PromotionQuizExporter from '../../exporter/promotion-quiz-exporter';

const PromotionQuizList = (props) => (
  <List
    title="Promoções Quiz"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ PromotionQuizExporter }
    actions={ <CustomListDefaultToolbar hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <ChipField source="title" label="Título" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
);

export default PromotionQuizList;
