import { SearchInput, ReferenceInput, SelectInput } from "react-admin";

const CustomFilterContact = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo" source="type_contact" reference="contact/types" alwaysOn >
    <SelectInput label="Tipo" optionText="name" optionValue="type" />
  </ReferenceInput>
];

export default CustomFilterContact;
