import { SearchInput, SelectInput } from "react-admin";

const CustomFilterChatBan = [
  <SearchInput source="q" alwaysOn />,
  <SelectInput label="Status" source="status_chat_ban" alwaysOn choices={[
      { id: 0, name: 'BANED' },
      { id: 1, name: 'REMOVED' }
  ]} />
];

export default CustomFilterChatBan;
