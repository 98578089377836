import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue(),
  description: hasValue(),
  event_init_date: hasValue(),
  event_end_date: hasValue()
});

const LiveEventCreate = () =>
{
  return (
    <Create
      title="Novo Evento da Live"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput multiline source="description" label="Descrição" fullWidth required />
        <DateTimeInput source="event_init_date" label="Data de Início" required />
        <DateTimeInput source="event_end_date" label="Data de Término" required />
      </SimpleForm>
    </Create>
  )
}

export default LiveEventCreate;
