import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = liveevents =>
{
  const data = liveevents.map(liveevent => ({
    id: liveevent.id,
    nome: liveevent.name,
    descricao: liveevent.description,
    data_inicio: liveevent.event_init_date_format,
    data_termino: liveevent.event_end_date_format,
    criado_em: liveevent.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'descricao', 'data_inicio', 'data_termino', 'criado_em']
  });
  downloadCSV(csv, 'liveevents');
}

export default exporter;
