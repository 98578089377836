import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';

const rowChatDenounceStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.status_chat_denounce === 0)
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status_chat_denounce === 1)
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status_chat_denounce === 2)
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return style;
};

export default rowChatDenounceStyle;
