import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import {
  hasValue,
  email,
  leastOneOption
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue(),
  email: email(),
  password: hasValue(),
  roles: leastOneOption(),
  username: hasValue()
});

const AdminCreate = () =>
{
  return (
    <Create
      title="Novo Usuário Admin"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput source="email" label="E-mail" fullWidth required />
        <PasswordInput source="password" label="Senha" fullWidth required />
        <TextInput source="username" label="Username" fullWidth required />
        <ReferenceArrayInput source="roles" sort={{ field: 'name', order: 'ASC' }} reference="admin/roles">
          <SelectArrayInput label="Permissão(ões)" optionText="name" optionValue="id" fullWidth required />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default AdminCreate;
