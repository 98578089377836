import { useState } from "react";
import jwt from "jwt-simple";
import axios from "axios";
import {
  DefaultEditorOptions,
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  useTiptapEditor,
} from "ra-input-rich-text";

import "tiptap-extension-upload-image/dist/upload-image.min.css";

import StarterKit from "@tiptap/starter-kit";
import Youtube from "@tiptap/extension-youtube";
import Link from "@tiptap/extension-link";
import UploadImage from "tiptap-extension-upload-image";

import { Image } from "@mui/icons-material";
import { ToggleButton, Button, TextField, Stack } from "@mui/material";
import { stringify } from "query-string";

import config from "../config/config";

const apiUrl = config.api.url;
const apiKey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageAuth = config.local_storage.auth;
const query = { apikey: apiKey };
const authToken = () => {
  return JSON.parse(
    jwt.decode(
      localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)),
      jwtKey
    )
  ).auth_token;
};

const uploadFn = (file) => {
  var formData = new FormData();
  formData.append("image", file);

  return axios
    .post(`${apiUrl}/image/body?${stringify(query)}`, formData, {
      headers: { Authorization: "Bearer " + authToken() },
    })
    .then((response) => {
      return response.data.result.url;
    })
    .catch((e) => {
      return "";
    });
};

const CustomRichTextInputToolbar = ({ size, ...props }) => {
  const editor = useTiptapEditor();
  return (
    <RichTextInputToolbar {...props}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack direction="row" gap={0.5}>
          <LevelSelect size={size} />
          <FormatButtons size={size} />
          <AlignmentButtons size={size} />
          <ListButtons size={size} />
          <LinkButtons size={size} />
          <QuoteButtons size={size} />
          <ClearButtons size={size} />
          <ToggleButton
            marked={false}
            size={size}
            onClick={() => editor.chain().focus().addImage().run()}
          >
            <Image />
          </ToggleButton>
        </Stack>
        <Stack
          direction="row"
          alignContent="center"
          justifyContent="center"
          gap={0.5}
        >
          <MenuBar editor={editor} />
        </Stack>
      </Stack>
    </RichTextInputToolbar>
  );
};

const MenuBar = ({ editor }) => {
  const [width, setWidth] = useState(640);
  const [height, setHeight] = useState(480);

  if (!editor) return null;

  const addYoutubeVideo = () => {
    const url = prompt("Informe a URL do vídeo");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: Math.max(320, parseInt(width, 10)) || 640,
        height: Math.max(180, parseInt(height, 10)) || 480,
      });
    }
  };

  return (
    <>
      <Button id="add" size="small" onClick={addYoutubeVideo}>
        YouTube
      </Button>
      <TextField
        type="number"
        min="320"
        max="1024"
        placeholder="width"
        value={width}
        sx={{
          width: 80,
          margin: 0,
        }}
        onChange={(e) => setWidth(parseInt(e.currentTarget.value))}
      />
      <TextField
        type="number"
        min="180"
        max="720"
        placeholder="height"
        value={height}
        sx={{
          width: 80,
          margin: 0,
        }}
        onChange={(e) => setHeight(parseInt(e.currentTarget.value))}
      />
    </>
  );
};

const CustomRichTextInput = ({ size, ...props }) => (
  <RichTextInput
    editorOptions={CustomEditorOptions}
    toolbar={<CustomRichTextInputToolbar size={size} />}
    sx={{
      "& .tiptap": {
        minHeight: 300,
      },
    }}
    {...props}
  />
);

export const CustomEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [
    ...DefaultEditorOptions.extensions,
    StarterKit.configure({
      history: false,
    }),
    Youtube.configure({
      controls: false,
    }),
    Link.configure({
      HTMLAttributes: {
        rel: null,
        target: null,
      },
    }),
    UploadImage.configure({
      uploadFn: uploadFn,
    }),
  ],
};

export default CustomRichTextInput;
