import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  type_url: hasValue(),
  url: hasValue()
});

const UrlEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar URL: " source="url" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput source="type_url" reference="url/types">
          <SelectInput label="Tipo de URL" optionText="name" optionValue="type" fullWidth required />
        </ReferenceInput>
        <TextInput source="url" label="URL" type="url" fullWidth required />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default UrlEdit;
