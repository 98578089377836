import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import ButtonEdit from '../../ui/button-edit';
import ButtonOpenLink from '../../ui/button-open-link';
import FileExporter from '../../exporter/file-exporter';

const FileList = (props) => (
  <List
    title="Arquivos para Download"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ FileExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false}/>
      <TextField source="program.name" label="Programa" sortable={false}/>
      <ButtonOpenLink source="url_file" text="Abrir" label="Arquivo" sortable={false}/>
      <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default FileList;
