import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  quiz_id: hasValue(),
  title: hasValue(),
  alternative_correct: hasValue(),
  alternative_incorrect_1: hasValue()
});

const QuestionCreate = () =>
{
  return (
    <Create
      title="Nova Questão"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <ReferenceInput source="quiz_id" reference="quiz">
          <SelectInput label="Quiz" optionText="title" optionValue="id" fullWidth required />
        </ReferenceInput>
        <TextInput source="title" label="Título" fullWidth required />
        <TextInput source="alternative_correct" label="Alternativa Correta" fullWidth required />
        <TextInput source="alternative_incorrect_1" label="Alternativa Errada 1" fullWidth required />
        <TextInput source="alternative_incorrect_2" label="Alternativa Errada 2" fullWidth />
        <TextInput source="alternative_incorrect_3" label="Alternativa Errada 3" fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default QuestionCreate;
