import React from 'react';
import {
  List,
  Datagrid,
  TextField
} from 'react-admin';
import CustomFilterPromotionQuizRanking from '../../filter/custom-filter-promotion-quiz-ranking';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import PromotionQuizRankingExporter from '../../exporter/promotion-quiz-ranking-exporter';

const PromotionQuizRankingList = (props) => (
  <List
    title="Ranking"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterPromotionQuizRanking }
    exporter={ PromotionQuizRankingExporter }
    actions={ <CustomListDefaultToolbar hasFilter={false} hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false}/>
      <TextField source="username" label="Username" sortable={false}/>
      <TextField source="points" label="Ponto(s)" sortable={false}/>
    </Datagrid>
  </List>
);

export default PromotionQuizRankingList;
