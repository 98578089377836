import {
  useRecordContext
} from "react-admin";
import React from 'react';

const styles =
{
  main:
  {
    display: 'flex',
    flexWrap: 'wrap'
  }
};

const LinkField = ({ source }) =>
{
  const record = useRecordContext();

  if (record[source] != null)
  {
    return (
      <span style={styles.main}>
      {
        <a
          href={record[source]}
          target="_blank"
          rel="noopener noreferrer"
          onClick={ (e) => { e.stopPropagation() }}
        >Abrir</a>
      }
      </span>
    );
  }
  else
    return null;
};

LinkField.defaultProps =
{
  addLabel: true,
  source: 'Link'
};

export default LinkField;
