import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = subscribers =>
{
  const data = subscribers.map(subscriber => ({
    id: subscriber.id,
    promocao: subscriber.promotion_name,
    nome: subscriber.profile.name,
    email: subscriber.email,
    username: subscriber.username,
    telefone: subscriber.profile.phone,
    data_nascimento: subscriber.profile.birthdate,
    endereco: subscriber.address.address,
    cep: subscriber.address.zip_code,
    bairro: subscriber.address.neighborhood,
    cidade: subscriber.address.city,
    estado: subscriber.address.state,
    criado_em: subscriber.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'promocao', 'nome', 'email', 'username', 'telefone',
             'data_nascimento', 'endereco', 'cep', 'bairro', 'cidade', 'estado',
             'criado_em']
  });
  downloadCSV(csv, 'subscribers');
}

export default exporter;
