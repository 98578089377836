import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = categories =>
{
  const data = categories.map(category => ({
    id: category.id,
    nome: category.name,
    seo_description: category.seo_description,
    criado_em: category.created_at
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'seo_description', 'criado_em']
  });
  downloadCSV(csv, 'categories');
}

export default exporter;
