import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = chatdenounces =>
{
  const data = chatdenounces.map(chatdenounce => ({
    id: chatdenounce.id,
    status: chatdenounce.status_chat_denounce_name,
    mensagem: chatdenounce.message,
    denunciado: chatdenounce.denounced.profile.name + ' - ' + chatdenounce.denounced.email,
    denunciado_por: chatdenounce.denunciator.profile.name + ' - ' + chatdenounce.denunciator.email,
    criado_em: chatdenounce.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'status', 'mensagem', 'denunciado', 'denunciado_por', 'criado_em']
  });
  downloadCSV(csv, 'chatdenounces');
}

export default exporter;
