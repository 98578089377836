import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = pqr =>
{
  const data = pqr.map(promotionquizranking => ({
    id: promotionquizranking.id,
    nome: promotionquizranking.name,
    username: promotionquizranking.username,
    pontos: promotionquizranking.points
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'nome', 'username', 'pontos']
  });
  downloadCSV(csv, 'ranking');
}

export default exporter;
