import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    DateField,
    BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterArticle from '../../filter/custom-filter-article';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import ArticleExporter from '../../exporter/article-exporter';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';

const ArticleList = (props) => (
  <List
    title="Artigos"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterArticle }
    exporter={ ArticleExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <CustomImageField source="image_thumb" label="Imagem Thumb" sortable={false}/>
      <FunctionField render={record => `${record.subcategory.category.name} - ${record.subcategory.name}`} label="Subcategoria"/>
      <TextField source="columnist.name" label="Colunista"/>
      <TextField source="title" label="Título"/>
      <TextField source="news_date_format" label="Data do Artigo"/>
      <DateField source="created_at" locales="pt-BR" label="Criado em"/>
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ArticleList;
