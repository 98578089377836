import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = promotions =>
{
  const data = promotions.map(promotion => ({
    id: promotion.id,
    titulo: promotion.title,
    descricao: promotion.description,
    inicio: promotion.init_at_format,
    fim: promotion.end_at_format,
    ganhadores: promotion.number_winners,
    participantes: promotion.count_participants,
    criado_em: promotion.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'titulo', 'descricao', 'inicio', 'fim', 'ganhadores',
             'participantes', 'criado_em']
  });
  downloadCSV(csv, 'promotions');
}

export default exporter;
