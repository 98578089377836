import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = articles =>
{
  const data = articles.map(article => ({
    id: article.id,
    categoria: article.subcategory.category.name,
    subcategoria: article.subcategory.name,
    colunista: article.columnist.name,
    titulo: article.title,
    texto: article.text,
    descricao: article.description,
    data: article.news_date_format,
    criado_em: article.created_at_format
  }));
  const csv = convertToCSV({
      data,
      fields: ['id', 'categoria', 'subcategoria', 'colunista', 'titulo', 'texto',
               'descricao', 'data', 'criado_em']
  });
  downloadCSV(csv, 'articles');
}

export default exporter;
