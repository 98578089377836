import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  program_id: hasValue(),
  question: hasValue(),
  init_at: hasValue(),
  end_at: hasValue(),
  option_1: hasValue(),
  option_2: hasValue()
});

const SurveyEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Enquete: " source="question" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput source="program_id" perPage={100} reference="program">
          <SelectInput label="Programa" optionText="name" optionValue="id" fullWidth required />
        </ReferenceInput>
        <TextInput source="question" label="Questão" fullWidth required />
        <DateTimeInput source="init_at" label="Início" required />
        <DateTimeInput source="end_at" label="Término" required />
        <TextInput source="option_1" label="Opção 1" fullWidth required />
        <TextInput source="option_2" label="Opção 2" fullWidth required />
        <TextInput source="option_3" label="Opção 3" fullWidth />
        <TextInput source="option_4" label="Opção 4" fullWidth />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default SurveyEdit;
