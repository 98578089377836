import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = questions =>
{
  const data = questions.map(question => ({
    id: question.id,
    quiz: question.quiz_title,
    titulo: question.title,
    alternativas: question.alternatives_name,
    criado_em: question.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ['id', 'quiz', 'titulo', 'alternativas', 'criado_em']
  });
  downloadCSV(csv, 'questions');
}

export default exporter;
