import { SearchInput, ReferenceInput, SelectInput, BooleanInput, DateTimeInput } from "react-admin";

const CustomFilterSurveyParticipants = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Enquente" source="survey_id" reference="survey" alwaysOn >
    <SelectInput label="Enquente" optionText="resume_question" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput label="Time" source="team_id" reference="team" >
    <SelectInput label="Time" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <BooleanInput source="profile_completed" label="Perfil Completo" />,
  <DateTimeInput source="date_limit" label="Data limite da participação" />
];

export default CustomFilterSurveyParticipants;
