import {
  Button,
  useRecordContext,
  useUpdate
} from "react-admin";
import IconApprove from "@mui/icons-material/CheckCircleOutline";

function ButtonApproveDenounce()
{
  const record = useRecordContext();
  const handleClick = (e) => { e.stopPropagation(); update(); }
  const [update, { isLoading }] = useUpdate('chatDenounce',
    { id: record.id, data: { status_chat_denounce: 1 }, previousData: record });

  if (record.status_chat_denounce === 0)
    return (
      <Button onClick={handleClick} disabled={isLoading} label="Aprovar">
        <IconApprove />
      </Button>
    );
  else
    return null;
}

export default ButtonApproveDenounce;
