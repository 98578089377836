import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = brands =>
{
  const data = brands.map(brand => ({
    id: brand.id,
    nome: brand.name,
    posicao: brand.position,
    criado_em: brand.created_at_format
  }));
  const csv = convertToCSV({
      data,
      fields: ['id', 'nome', 'posicao', 'criado_em']
  });
  downloadCSV(csv, 'brands');
}

export default exporter;
