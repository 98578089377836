import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  type_url: hasValue(),
  url: hasValue()
});

const UrlCreate = () =>
{
  return (
    <Create
      title="Nova URL"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <ReferenceInput source="type_url" reference="url/types">
          <SelectInput label="Tipo de URL" optionText="name" optionValue="type" fullWidth required />
        </ReferenceInput>
        <TextInput source="url" label="URL" type="url" fullWidth required />
      </SimpleForm>
    </Create>
  )
}

export default UrlCreate;
