import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import CustomImageField from '../../ui/custom-image-field';
import ButtonEdit from '../../ui/button-edit';
import ButtonMenuExporter from '../../exporter/button-menu-exporter';
import { ColorField } from 'react-admin-color-picker';

const ButtonMenuList = (props) => (
  <List
    title="Botões do Menu"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ ButtonMenuExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="type_button_menu_name" label="Tipo" sortable={false} />
      <CustomImageField source="image_cover" label="Imagem" sortable={false} />
      <TextField source="text" label="Texto" sortable={false} />
      <ColorField source="color_background" label="Cor de Background" sortable={false}/>
      <ColorField source="color_font" label="Cor da Fonte" sortable={false}/>
      <TextField source="link" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ButtonMenuList;
