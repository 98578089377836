import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import GenderExporter from '../../exporter/gender-exporter';

const GenderList = (props) => (
  <List
    title="Gêneros"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ GenderExporter }
    actions={ <CustomListDefaultToolbar hasFilter={false} hasCreate={false} /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" label="Id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
    </Datagrid>
  </List>
);

export default GenderList;
