import React from 'react';
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterChatBan from '../../filter/custom-filter-chat-ban';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import ChatBanExporter from '../../exporter/chat-ban-exporter';
import BannedField from '../../ui/banned-field';
import ButtonRemoveBan from '../../ui/button-remove-ban';
import rowChatBanStyle from '../../ui/row-chat-ban-style';

const ChatBanList = (props) => (
  <List
    title="Banidos do Chat"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterChatBan }
    exporter={ ChatBanExporter }
    actions={ <CustomListDefaultToolbar hasFilter={false}  hasCreate={false} /> }>
    <Datagrid
      rowStyle={rowChatBanStyle()}
      bulkActionButtons={false}>
      <TextField source="status_chat_ban_name" label="Status" sortable={false} />
      <BannedField />
      <TextField source="chat_denounce.message" label="Mensagem" sortable={false} />
      <TextField source="created_at_format" label="Banido em" sortable={false} />
      <GroupButtons label="Ações">
        <ButtonRemoveBan />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ChatBanList;
