import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomColorInput from "../../ui/custom-color-input";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  category_id: hasValue(),
  name: hasValue(),
  seo_description: hasValue(),
  color_base: hasValue()
});

const SubcategoryEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Subcategoria: " source="name" />}
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput source="category_id" filter={{ active: true }} perPage={100} reference="category">
          <SelectInput label="Categoria" optionText="name" optionValue="id" fullWidth required />
        </ReferenceInput>
        <TextInput source="name" label="Nome" fullWidth required />
        <TextInput source="seo_description" label="Descrição para o SEO" fullWidth required />
        <CustomColorInput source="color_base" label="Cor Base" isRequired />
        <br/>
        <BooleanInput source="show_in_menu" label="Aparecer no Menu" />
        <BooleanInput source="short_content" label="Conteúdo Reduzido" />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default SubcategoryEdit;
