import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomColorInput from "../../ui/custom-color-input";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object({
  link: hasValue(),
  text: Yup.string().when("type_button_menu", {
    is: 0,
    then: () => Yup.string().required("Obrigatório"),
  }),
  color_background: Yup.string().when("type_button_menu", {
    is: 0,
    then: () => Yup.string().required("Obrigatório"),
  }),
  color_font: Yup.string().when("type_button_menu", {
    is: 0,
    then: () => Yup.string().required("Obrigatório"),
  }),
});

const ButtonMenuEdit = () => {
  return (
    <Edit
      title={<EditTitle subtitle="Editar Botão do Menu: " source="link" />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="type_button_menu_name" label="Tipo" disabled />
        <TextInput source="link" label="Link" type="url" fullWidth required />
        <FormDataConsumer>
          {({ formData }) =>
            formData &&
            formData.type_button_menu != null &&
            formData.type_button_menu === 0 ? (
              <>
                <TextInput source="text" label="Texto" fullWidth required />
                <CustomColorInput
                  source="color_background"
                  label="Cor de Background"
                  isRequired
                />
                <CustomColorInput
                  source="color_font"
                  label="Cor da Fonte"
                  isRequired
                />
                <br />
              </>
            ) : formData &&
              formData.type_button_menu != null &&
              formData.type_button_menu === 1 ? (
              <ImageInput
                source="image"
                label="Imagem (128x64) Max: 1MB"
                maxSize={1000000}
                accept="image/png,image/gif,image/jpeg,image/jpg"
                required
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            ) : null
          }
        </FormDataConsumer>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default ButtonMenuEdit;
